import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, Button } from "../../components";
import { Link } from "react-router-dom";
import Header from "../../components/Header";

export default function SecurityVerificationMobilePage() {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [timeLeft, setTimeLeft] = useState(179); // Initial time left in seconds (3 minutes - 1)
  const inputRefs = useRef([]);

  const updateTimer = () => {
    if (timeLeft > 0) {
      setTimeLeft((prevTime) => prevTime - 1);
    }
  };

  // Run the updateTimer function every second
  useEffect(() => {
    const timer = setTimeout(() => {
      updateTimer();
    }, 1000);

    return () => clearTimeout(timer); // Clear timeout when component unmounts
  }, [timeLeft]);

  // Format time left into MM:SS format
  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleChange = (element, index) => {
    const value = element.value;
    const newOtp = [...otp];

    if (value === "") {
      if (index > 0 && element.inputType === "deleteContentBackward") {
        inputRefs.current[index - 1].focus();
      }
    } else {
      if (!isNaN(value)) {
        newOtp[index] = value;
        setOtp(newOtp);

        // Focus next input
        if (index < 5) {
          inputRefs.current[index + 1].focus();
        }
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = "";
        return newOtp;
      });

      if (e.key === "Backspace" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else if (e.key === "Delete" && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <Header className="self-stretch" />
      
      <div className="flex w-full bg-white-A700 pt-[45px] md:pt-5">
        <div className="container-md md:w-[100%] flex items-start justify-between gap-5 md:flex-col md:p-5">
          <div
            // style={{ width: "auto" }}
            className="flex w-full md:w-[50%] flex-col items-start gap-[20px] mt-[100px] sm:mt-[10px] md:gap-[20px] sm:w-auto sm:gap-[20px] pl-[110px] md:p-5 md:px-5"
          >
            <div className="flex flex-col items-start gap-[13px] self-stretch">
              <div className="flex flex-col items-start gap-[30px] self-stretch md:mr-0 ">
                <Text
                  size="lg"
                  as="p"
                  className="text-black-900 bold mobile"
                  style={{ fontSize: "30px" }}
                >
                  <b> Mobile Verification</b>
                </Text>
                <Text size="l" as="p" className="!text-black-900_99 mobile">
                  Please enter the 6 digit verification code that was sent to
                  +91-XXXXXXXXXX The code is valid for 3 minutes.
                </Text>
                <Text
                  size="lg"
                  as="p"
                  className="!text-black-900 bold"
                  style={{ fontSize: "30px" }}
                >
                  <b>OTP</b>
                </Text>

                <div className="flex flex-col items-start gap-[15px] self-stretch">
                  <Text size="2xl" as="p" className="!text-white-A700_01">
                    <span className="text-black-900_01">Enter Otp&nbsp;</span>
                    <span className="text-deep_orange_A400"> *</span>
                  </Text>
                  <div className="flex flex-wrap gap-[13px] self-stretch md:flex-nowrap md:flex-row md:gap-2 lg:flex-nowrap lg:flex-row lg:gap-2 otp-box">
                    {otp.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="h-[50px] flex items-center justify-center rounded-[5px] border border-gray-400 bg-gray-200 otp-input-container w-[50px] md:w-[50px] lg:w-[50px]"
                        >
                          <input
                            ref={(el) => (inputRefs.current[index] = el)}
                            className="w-full  h-full text-center bg-transparent border-none outline-none otp-input"
                            type="text"
                            name="otp"
                            maxLength="1"
                            value={data}
                            onChange={(e) => handleChange(e.target, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onFocus={(e) => e.target.select()}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex justify-end items-center w-full">
                    <Text
                      size="md"
                      as="p"
                      className="mr-[5px] !text-indigo-A200 md:mr-0"
                    >
                      {formatTimeLeft()}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap gap-[13px] self-stretch md:flex-nowrap md:flex-row md:gap-2 lg:flex-nowrap lg:flex-row lg:gap-2 otp-box">
                <Button
                  color="gray_900_04"
                  className="skip-button w-full rounded-[5px] font-manrope font-bold sm:px-5"
                >
                  <Link
                    to="/personalinformation"
                    style={{ color: "#ffffff", textDecoration: "none" }}
                  >
                    Submit
                  </Link>
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-[100px] md:hidden flex w-full md:w-[58%] flex-col items-start form-2-section">
            <Heading as="h1">Mobile Verification</Heading>
            <Text size="l" as="p" className="mt-2 !text-black-900_99 mr-5">
              Please enter the 6 digit verification code that was sent to
              XXXXXXXXXX. The code is valid for 3 minutes.
            </Text>
            
          </div>
        </div>
      </div>
    </>
  );
}
