import React from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Heading } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
export default function PlansPage() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex w-full flex-col items-center bg-white-A700">
        <Header className="self-stretch" />
        <div className="container-md mt-[10px] flex justify-center md:p-5 p-20">
          <div className="flex w-full flex-col items-center gap-[38px]">
            <div className="flex w-[58%] flex-col items-center gap-[11px] md:w-full">
              <Heading
                as="h1"
                className="mr-[57px] !text-black-900 md:mr-0 md:w-full md:text-center"
              >
                Barter Certificate Plans
              </Heading>
              <Text
                as="p"
                className="w-full text-center !font-normal leading-7"
              >
                Quickall's Barter certificate plans are designed for everyone. 
                Select a suitable plan to become a Money Manager and participate in secure, 
                efficient trading.
              </Text>
            </div>
            <div className="flex gap-8 md:flex-col md:full w-[80%]">
              <div className="flex md:w-auto w-1/3 flex-col items-center rounded-[12px] bg-gray-100 px-[15px] pb-[62px] pr-[54px] pl-[54px] pt-[15px] md:pb-5">
                <Heading as="h4" className="text-shadow-ts !text-gray-900_03">
                  Silver
                </Heading>
                <Heading
                  size="xs"
                  as="h6"
                  className="mt-[30px] !text-gray-900_03"
                >
                  $500 - $10000
                </Heading>
                <Text
                  as="p"
                  className="mt-[17px] w-full !font-normal leading-7 md:w-full"
                >
                  You will get equivelent value of G-Coins after 6 months.
                </Text>
                
                <Button
                  color="teal_900"
                  onClick={() => {
                    navigate("/bartercertificatesilver");
                  }}
                  className="mt-[85px] w-[100%] border-2 border-solid rounded-full border-white-A700 font-medium md:ml-0 sm:px-5"
                >
                  Get started
                </Button>
              </div>
              <div className="flex md:w-auto w-1/3 flex-col items-end rounded-[12px] bg-gradient2 pb-[62px] pl-[54px] pr-[54px] pt-[15px] md:px-5 md:pb-5">
                <Heading
                  as="h4"
                  className="text-shadow-ts w-full text-center !text-gray-900_03"
                >
                  GOLD
                </Heading>
                <Heading
                  size="xs"
                  as="h6"
                  className="mt-[30px] self-center !text-gray-900_03"
                >
                  $10001 - $20000
                </Heading>
                <Text
                  as="p"
                  className="mt-[17px] w-full !font-normal leading-7"
                >
                  You will get equivelent value of G-Coins after 6 months and bonus of 5%.
                </Text>
                
                <Button
                  color="teal_900"
                  onClick={() => {
                    navigate("/bartercertificategold");
                  }}
                  className="mt-[57px] w-[100%] border-2 border-solid rounded-full border-white-A700 font-medium md:ml-0 sm:px-5"
                >
                  Get started
                </Button>
              </div>
              <div className="flex md:w-auto w-1/3 flex-col items-start rounded-[12px] bg-gradient3 pb-[62px] pr-[54px] pl-[54px] pt-[15px] md:pb-5 md:pl-5 sm:px-5">
                <Heading
                  as="h4"
                  className="w-full text-center text-shadow-ts !text-gray-900_03"
                >
                  PLATINUM
                </Heading>
                <Heading
                  size="xs"
                  as="h1"
                  className="mt-[25px] !text-gray-900_03 leading-10"
                >
                  $20001 and above
                </Heading>
                <Text
                  as="p"
                  className="mt-[13px] w-full !font-normal leading-7 md:w-full"
                >
                  You will get equivelent value of G-Coins after 6 months and bonus of 10%.
                </Text>
                
                <Button
                  color="teal_900"
                  onClick={() => {
                    navigate("/bartercertificateplatinium");
                  }}
                  className="mt-[58px] w-[100%] border-2 border-solid rounded-full border-white-A700 font-medium md:ml-0 sm:px-5"
                >
                  Get started
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer className="mt-[173px] self-stretch" />
      </div>
    </>
  );
}
