import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Heading } from "../../components";
import Footer from "../../components/Footer";
import Header1 from "../../components/Header1";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function Plans1Page() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [username, setUsername] = useState("");

  // Example token, replace with actual token fetching logic
  const token = localStorage.getItem("authToken"); // Get the token from localStorage or another source

  // Fetch user login details
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get("http://134.122.118.11:3001/login", {
          headers: {
            Authorization: 'Bearer ' + token, // Pass the Bearer token in the Authorization header
          },
        });
        if (response.data && response.data.user) {
          setUsername(response.data.user); // Set the username if available
        }
      } catch (error) {
        console.error("Error fetching user details", error);
        // Handle error appropriately (e.g., redirect to login page or show error message)
      }
    };

    if (token) {
      fetchUserDetails(); // Call API only if token exists
    } else {
      console.error("No token found, redirecting to login...");
      // Optionally redirect to login page if token is missing
    }
  }, [token]);
  // Handle input changes
  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  // Validate the input
  const validate = () => {
    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      setError('Please enter a valid amount greater than zero.');
      return false;
    }
    if (Number(amount) < 500) {
      setError('Amount must be greater than $500 .');
      return false;
    }
    setError('');
    return true;
  };

 // Handle form submission
 const handleClick = async (e) => {
  e.preventDefault(); // Prevent default form submission

  if (!validate()) return; // Validate input

  try {
    // Prepare data for NOWPayments
    const paymentData = {
      amount: Number(amount),
      currency: 'usd', // Change this if needed
    };
    const token = localStorage.getItem("authToken"); // Get the token from localStorage or another source
    // Send the payment request to your server
    const response = await axios.post('http://134.122.118.11:3001/process-payment', paymentData,{
      headers: {
        'Authorization': 'Bearer ' + token,  // Replace YOUR_ACCESS_TOKEN with your actual token
        'Content-Type': 'application/json'
      }});
    console.log('Response:', response.data);

    // Now redirect the user to the payment URL or handle it as needed
    if (response.data.paymentUrl) {
      window.location.href = response.data.paymentUrl; // Redirect to NOWPayments payment page
    } else {
      setMessage('Failed to get payment URL.');
      navigate('/userdashboard');
    }
  } catch (error) {
    console.error('Error processing payment:', error.response?.data || error.message);
    setMessage('Error processing payment.');
    navigate('/userdashboard');
  }
};


  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex w-full flex-col items-center bg-white-A700">
        <Header1 className="self-stretch" username={username} />
        <div className="container-md mt-[10px] flex justify-center md:p-5 p-20">
          <div className="flex w-full flex-col items-center gap-[38px]">
            <div className="flex w-[58%] flex-col items-center gap-[11px] md:w-full">
              <Heading
                as="h1"
                className="mr-[57px] mt-[-40px] !text-black-900 md:mr-0 md:w-full md:text-center"
              >
                Barter Certificate Plans
              </Heading>
              <Text
                as="p"
                className="w-full text-center !font-normal leading-7"
              >
                Quickall's Barter certificate plans are designed for everyone.
                Select a suitable plan to become a Money Manager and participate in secure,
                efficient trading.
              </Text>
            </div>
            <div className="flex gap-8 md:flex-col md:full w-[80%]">
              <div className="flex md:w-auto w-1/3 flex-col items-center rounded-[12px] bg-gray-100 px-[15px] pb-[62px] pr-[54px] pl-[54px] pt-[15px] md:pb-5">
                <Heading as="h4" className="text-shadow-ts !text-gray-900_03">
                  Silver
                </Heading>
                <Heading
                  size="xs"
                  as="h6"
                  className="mt-[30px] !text-gray-900_03"
                >
                  $500 - $10000
                </Heading>
                <Text
                  as="p"
                  className="mt-[17px] w-full !font-normal leading-7 md:w-full"
                >
                  You will get equivelent value of G-Coins after 6 months.
                </Text>


              </div>
              <div className="flex md:w-auto w-1/3 flex-col items-end rounded-[12px] bg-gradient2 pb-[62px] pl-[54px] pr-[54px] pt-[15px] md:px-5 md:pb-5">
                <Heading
                  as="h4"
                  className="text-shadow-ts w-full text-center !text-gray-900_03"
                >
                  GOLD
                </Heading>
                <Heading
                  size="xs"
                  as="h6"
                  className="mt-[30px] self-center !text-gray-900_03"
                >
                  $10001 - $20000
                </Heading>
                <Text
                  as="p"
                  className="mt-[17px] w-full !font-normal leading-7"
                >
                  You will get equivelent value of G-Coins after 6 months and bonus of 5%.
                </Text>


              </div>
              <div className="flex md:w-auto w-1/3 flex-col items-start rounded-[12px] bg-gradient3 pb-[62px] pr-[54px] pl-[54px] pt-[15px] md:pb-5 md:pl-5 sm:px-5">
                <Heading
                  as="h4"
                  className="w-full text-center text-shadow-ts !text-gray-900_03"
                >
                  PLATINUM
                </Heading>
                <Heading
                  size="xs"
                  as="h1"
                  className="mt-[25px] !text-gray-900_03 leading-10"
                >
                  $20001 and above
                </Heading>
                <Text
                  as="p"
                  className="mt-[13px] w-full !font-normal leading-7 md:w-full"
                >
                  You will get equivelent value of G-Coins after 6 months and bonus of 10%.
                </Text>


              </div>

            </div>

          </div>
        </div>
        <form className="CreateaccountPage flex items-center gap-2 mt-[-60px]">
          <input
            className="h-14 custom-border"
            type="number"
            name="amount"
            placeholder="Enter amount"
            value={amount}
            onChange={handleInputChange}
          />
          <Button
            color="teal_900"
            onClick={handleClick}
            className="mt-[10px] w-[100%] border-2 border-solid rounded-full border-white-A700 font-medium md:ml-0 sm:px-5"
          >
            Buy
          </Button>
          {message && <p>{message}</p>}
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>
        <Footer className="mt-[173px] self-stretch" />
      </div>
    </>
  );
}
