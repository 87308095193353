import React from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Heading, Button } from "../../components";
import { Link } from "react-router-dom";
import Header from "../../components/Header";

export default function HeretoregisterPage() {
  return (
    <>
      <Helmet>
        <title>Register Account</title>
        <meta
          name="description"
          content="Create your account with QUICKALL in minutes. Our straightforward registration process includes verification, personal information review, and a quick finish. Let's get started!"
        />
      </Helmet>
      <Header className="self-stretch" />
      {/* Header 
      <div className="form-header">
        <Link to={"/"}>
          <div className="flex md:w-[40%] lg:w-[50%] items-center justify-start">
            <Img
              src="images/img_logo_transperent.png"
              alt="logo image"
              className="h-[66px] w-[65px] object-cover"
            />
            <Heading size="s" as="h3" className="pl-2 h-full !text-white-A700">
              QUICKALL
            </Heading>
          </div>
        </Link>
      </div>
        */}

      {/* Registration process section */}
      <div className="flex w-full justify-end bg-white-A700 pt-[45px] pb-[45px] md:pt-5">
        <div className="mx-auto flex w-full max-w-[1566px] items-start justify-between gap-5 md:flex-col md:p-5">
          {/* Registration steps */}
          <div className="flex flex-col w-[50%] md:w-full relative">
            <div className="mt-8 flex flex-col items-start self-stretch pl-[110px] md:p-5 md:px-5">
              <Heading className="text-2xl mt-[-20px]">
                {" "}
                Register
              </Heading>
              <div className="flex flex-col items-start w-full mt-[50px]">
                <Step
                  stepNumber={1}
                  title="Create Account"
                  description=""
                  iconSrc="images/profile-user.png"
                  
                />
                <VerticalLine />
                <Step
                  stepNumber={2}
                  title="Verification"
                  description=""
                  iconSrc="images/validate.png"
                />
                <VerticalLine />
                <Step
                  stepNumber={3}
                  title="Personal Information"
                  description=""
                  iconSrc="images/personal-information.png"
                />
                <VerticalLine />
                <Step
                  stepNumber={4}
                  title="Review Details"
                  description=""
                  iconSrc="images/review.png"
                />
                <VerticalLine />
                <Step
                  stepNumber={5}
                  title="Finish Application"
                  description=""
                  iconSrc="images/flag.png"
                />
              </div>
              
            </div>
          </div>

          {/* Registration introduction section */}
          <div className="relative md:text-center h-[677px] w-[75%] md:h-[300px] md:w-full">
            <div className="absolute md:text-center left-[0.00px] top-[0.00px] m-auto flex xl:w-[45%] flex-col items-center">
              <Heading as="h1">Here To Register</Heading>
              <div className="relative mt-[10px] pl-[13px]">
                <Text as="p" className="leading-7 !text-black-900_99">
                  To get started, we need you to create an account with us.
                  It&#39;s a quick and straightforward process that will only
                  take a few minutes.
                </Text>
                <button
                style={{
                  marginTop: "34px",
                  width: "100%",
                  borderRadius: "5px",
                  backgroundColor: "#5F2C0F", // Gray color for visibility
                  color: "#ffffff",
                  padding: "10px 0",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "bold",
                  border: "none",
                }}
              >
                <Link
                  to="/createaccount"
                  style={{ color: "#ffffff", textDecoration: "none" }}
                >
                  Let's Get Started
                </Link>
              </button>
              </div>
            </div>
              
            <div className="md:hidden">
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Step({ stepNumber, title, description, iconSrc }) {
  return (
    <div className="flex items-center gap-4 relative">
      <Button
        shape="rectangular"
        className="w-[40px] border border-solid border-black-900 rounded-[10px] relative z-10 bg-white"
      >
        <Img
          src={iconSrc}
          alt={`step ${stepNumber} icon`}
          style={{ maxWidth: "none", width: "inherit" }}
        />
      </Button>
      <div className="flex flex-col">
        <Text as="p" className="font-bold">
          {title}
        </Text>
        {description && (
          <Text as="p" className="text-black-900_99">
            {description}
          </Text>
        )}
      </div>
    </div>
  );
}

function VerticalLine() {
  return (
    <div className="ml-5 h-[50px] w-[1px] bg-black-900 relative left-[20px]" />
  );
}
