import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Home1 from "pages/Home1";
import Aboutus from "pages/Aboutus";
import BarterCertificateGold from "pages/BarterCertificateGold";
import BarterCertificateSilver from "pages/BarterCertificateSilver";
import BarterCertificatePlatinium from "pages/BarterCertificatePlatinium";
import Work from "pages/Work";
import Contactus from "pages/Contactus";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Plans from "pages/Plans";
import Plans1 from "pages/Plans1";
import Features from "pages/Features";
import BlogPage from "pages/BlogPage";
import ReadBlog from "pages/ReadBlog";
import ReadBlog1 from "pages/ReadBlog1";
import ReadBlog2 from "pages/ReadBlog2";
import ReadBlog3 from "pages/ReadBlog3";
import ReadBlog4 from "pages/ReadBlog4";
import ReadBlog5 from "pages/ReadBlog5";
import SecurityVerificationEmail from "pages/SecurityVerificationEmail";
import SecurityVerificationMobile from "pages/SecurityVerificationMobile";
import Createaccount from "pages/Createaccount";
import PersonalInformation from "pages/PersonalInformation";
import Login from "pages/Login";
import Heretoregister from "pages/Heretoregister";
import Disclaimer from "pages/Disclaimer";
import TermsofServices from "pages/TermsofServices";
import CopyRightCertificate from "pages/CopyRightCertificate";
import BannerPage from "pages/BannerPage";
import UserDashboard from "pages/UserDashboard";
const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <BannerPage /> },
    { path: "*", element: <NotFound /> },
    {
      path: "home1",
      element: <Home1 />,
    },
    {
      path: "aboutus",
      element: <Aboutus />,
    },
    {
      path: "bartercertificategold",
      element: <BarterCertificateGold />,
    },
    {
      path: "bartercertificatesilver",
      element: <BarterCertificateSilver />,
    },
    {
      path: "bartercertificateplatinium",
      element: <BarterCertificatePlatinium />,
    },
    {
      path: "work",
      element: <Work />,
    },
    {
      path: "contactus",
      element: <Contactus />,
    },
    {
      path: "privacypolicy",
      element: <PrivacyPolicy />,
    },
    {
      path: "plans",
      element: <Plans />,
    },
    {
      path: "plans1",
      element: <Plans1 />,
    },
    {
      path: "features",
      element: <Features />,
    },
    {
      path: "blogpage",
      element: <BlogPage />,
    },
    {
      path: "readblog",
      element: <ReadBlog />,
    },
    {
      path: "readblog1",
      element: <ReadBlog1 />,
    },
    {
      path: "readblog2",
      element: <ReadBlog2 />,
    },
    {
      path: "readblog3",
      element: <ReadBlog3 />,
    },
    {
      path: "readblog4",
      element: <ReadBlog4 />,
    },
    {
      path: "readblog5",
      element: <ReadBlog5 />,
    },
    {
      path: "securityverificationemail",
      element: <SecurityVerificationEmail />,
    },
    {
      path: "securityverificationmobile",
      element: <SecurityVerificationMobile />,
    },
    {
      path: "createaccount",
      element: <Createaccount />,
    },
    {
      path: "personalinformation",
      element: <PersonalInformation />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "heretoregister",
      element: <Heretoregister />,
    },
    {
      path: "disclaimer",
      element: <Disclaimer />,
    },
    {
      path: "termsofservices",
      element: <TermsofServices />,
    },
    {
      path: "copyrightcertificate",
      element: <CopyRightCertificate />,
    },
    {
      path: "bannergpage",
      element: <BannerPage />,
    },
    {
      path: "userdashboard",
      element: <UserDashboard />,
    },
    
  ]);

  return element;
};

export default ProjectRoutes;
