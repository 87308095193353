import React from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Heading } from "../../components";
import AboutUsCard from "../../components/AboutUsCard";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import timelineBulletPoints from "../../assets/images/timelineIcon.png";

const data = [
  { name: "G Chin", designation: "CEO-Founder", imgSrc: "images/img_ellipse_228.png" },
  
  {
    name: "Nitin Sahni",
    designation: "CTO-Cofounder",
    imgSrc: "images/img_nitin_sahni.png",
  },
 
  {
    name: "Vikas Jain",
    designation: "Project Manager",
    imgSrc: "images/img_https_www_pex_4.png",
  },
];

export default function AboutusPage() {
  return (
    <>
      <Helmet>
        <title>About Quickall - Our Mission, Vision, and Team</title>
        <meta
          name="description"
          content="Learn about Quickall's mission to create a secure blockchain ecosystem with AI, PoMT, and decentralized KYC. Meet our dedicated team leading the charge."
        />
      </Helmet>
      <div className="w-full bg-white-A700">
        {/* navigation bar section */}
        <Header />
        <div className="md:mt-8 mt-20 flex flex-col items-center gap-32 md:gap-24 sm:gap-16">
          {/* about us intro section */}
          <div className="container-xs flex flex-col gap-32 md:gap-12 md:p-5 md:px-4 px-4">
            <div className="flex items-center justify-between gap-5 md:flex-col">
              <div className="mb-[9px] flex w-[46%] flex-col items-start self-end md:w-full">
                <Text
                  size="md"
                  as="p"
                  className="!font-normal !text-blue_gray-900_01"
                >
                  About us
                </Text>
                <Text
                  as="h1"
                  className="md:mt-0 md:leading-9 sm:text-3xl md:text-4xl text-5xl mt-[11px] w-full leading-[74px] font-semibold text-[#000]"
                >
                  Our blockchain solve problems
                </Text>
                <Text
                  as="p"
                  className="md:mt-2 mt-4 w-[81%] text-justify !font-normal md:leading-5 leading-7 md:w-full"
                >
                  QuickAll Blockchain provides decentralized, AI-powered technology that improves 
                  the security, scalability, and efficacy of a wide range of business applications 
                  in order to address critical issues with novel solutions.
                </Text>
              </div>
              <Img
                src="images/hero.svg"
                alt="hero image"
                className="h-[350px] w-[50%] object-cover md:w-full"
              />
            </div>

            {/* who we are section */}
            <div>
              <div className="flex justify-center bg-gray-900_0c px-14 pb-20 pt-[82px] md:p-5">
                <div className="flex w-[93%] flex-col items-start md:w-full">
                  <Text as="p" className="!text-blue_gray-900_01">
                    Who we are
                  </Text>

                  <div className="flex justify-center items-center flex-row md:flex-col md:gap-3">
                    <div className="h-full w-1/2 md:w-full flex flex-col gap-3 md:gap-1">
                      <Text
                        as="h2"
                        className="w-full !text-gray-900 text-4xl font-semibold sm:text-3xl py-1"
                      >
                        Pioneers of 100% Decentralized Blockchain
                      </Text>

                      <Text as="p" className="w-[90%] !font-normal leading-7">
                      Quickall takes great pride in being at the forefront of 100% decentralized 
                      blockchain technology. Our AI-powered platform guarantees security, 
                      scalability, and efficiency that are unmatched, thereby transforming 
                      industries and establishing fresh benchmarks for decentralized solutions 
                      worldwide.
                      </Text>
                    </div>
                    <div className="h-full w-1/2 md:w-full flex flex-col gap-3 md:gap-1">
                      <Text
                        as="h2"
                        className="w-full !text-gray-900 text-4xl font-semibold sm:text-3xl py-1"
                      >
                        Innovators of PoMT Consensus
                      </Text>

                      <Text as="p" className="w-[90%]  !font-normal leading-7">
                      We are the pioneers of the Proof of Machine Trust (PoMT) validation 
                      mechanism. This innovative mechanism integrates artificial intelligence, 
                      Proof of Stake, and Proof of Reputation to provide blockchain technology 
                      with unparalleled efficiency, security, and decentralization.
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              <Img
                src="images/img_https_www_pex_340x1280.png"
                alt="who we are image"
                className="h-[340px] w-full object-cover md:h-[200px] sm:h-[180px]"
              />
            </div>

            {/* roadmap section */}
            <div className="flex justify-center items-center gap-16 sm:gap-6 flex-col">
              <Text
                as="h4"
                className="w-full leading-7 sm:text-3xl md:text-3xl xl:text-5xl  text-[#000] font-semibold sm:text-left text-center"
              >
                The Roadmap
              </Text>
              <div className="w-full flex flex-row sm:flex-col justify-between items-center sm:gap-5">
                <div className="flex w-1/4 flex-col items-start sm:w-full sm:p-3">
                  <img
                    src={timelineBulletPoints}
                    alt="timeline_icon"
                    className="h-[20px] w-[20px]"
                  />
                  <Text
                    size="lg"
                    as="p"
                    className="mt-8 !text-blue_gray-900_01 sm:mt-1"
                  >
                    Q1 - 2024
                  </Text>
                  <Text
                    as="p"
                    className="mt-[7px] w-full !font-normal leading-7"
                  >
                    Preparation of concept and White paper/Lite paper
                  </Text>
                </div>

                <div className="flex w-1/4 flex-col items-start sm:w-full sm:p-3">
                  <img
                    src={timelineBulletPoints}
                    alt="timeline_icon"
                    className="h-[20px] w-[20px]"
                  />
                  <Text
                    size="lg"
                    as="p"
                    className="mt-8 !text-blue_gray-900_01 sm:mt-1"
                  >
                    Q2 - 2024
                  </Text>
                  <Text
                    as="p"
                    className="mt-[7px] w-full !font-normal leading-7"
                  >
                    Launch of website and Barter Certificate.
                  </Text>
                </div>

                <div className="flex w-1/4 flex-col items-start sm:w-full sm:p-3">
                  <img
                    src={timelineBulletPoints}
                    alt="timeline_icon"
                    className="h-[20px] w-[20px]"
                  />
                  <Text
                    size="lg"
                    as="p"
                    className="mt-8 !text-blue_gray-900_01 sm:mt-1"
                  >
                    Q3 - 2024
                  </Text>
                  <Text
                    as="p"
                    className="mt-[7px] w-full !font-normal leading-7"
                  >
                    Development of MVP and launch of G Coin
                  </Text>
                </div>

                <div className="flex w-1/4 flex-col items-start sm:w-full sm:p-3">
                  <img
                    src={timelineBulletPoints}
                    alt="timeline_icon"
                    className="h-[20px] w-[20px]"
                  />
                  <Text
                    size="lg"
                    as="p"
                    className="mt-8 !text-blue_gray-900_01 sm:mt-1"
                  >
                    Q4 - 2024
                  </Text>
                  <Text
                    as="p"
                    className="mt-[7px] w-full !font-normal leading-7"
                  >
                    Launch of first decentralized AI component
                  </Text>
                </div>
              </div>
            </div>
          </div>

          {/* mission vision section */}
          <div className="w-full flex flex-col items-center justify-center bg-gray-900_0c pb-[103px] pt-32 gap-12 md:gap-0 md:py-5">
            <div className="mx-auto flex w-full max-w-[1278px] items-center justify-between gap-5 md:flex-col md:p-5 md:gap-0">
              <div className="mb-[41px] md:mb-0 flex w-[49%] flex-col items-start gap-3.5 self-end md:w-full md:gap-1">
              <Text as="h2"
                    className="w-full !text-gray-900 text-4xl font-semibold sm:text-3xl py-1"
                      >
                  Our Mission
                </Text>
                <Text as="p" className="w-[90%]  !font-normal leading-7">
                  We are creating QUICKALL to establish, maintain, and promote a
                  blockchain ecosystem that is specially designed to protect
                  enterprises, individuals, and organizations from the risks
                  related to malicious parties.
                </Text>
              </div>
              <Img
                src="images/img_https_www_pex_336x515.png"
                alt="vision image"
                className=" h-[336px] w-[40%] object-cover md:w-full md:mt-[20px] "
              />
            </div>
            <div className="mx-auto flex w-full max-w-[1284px] items-center justify-between gap-5 md:flex-col md:p-5">
              <Img
                src="images/img_https_www_pex_285x405.svg"
                alt="mission image"
                className="visible md:hidden h-[334px] w-[40%] object-cover md:w-full"
              />
              <div className="flex w-[49%] flex-col items-start md:w-full md:gap-1">
              <Text as="h2"
                    className="w-full !text-gray-900 text-4xl font-semibold sm:text-3xl py-1"
                      >
                  Our Vision
                </Text>
                <Img
                  src="images/img_https_www_pex_285x405.svg"
                  alt="mission image"
                  className="xl:hidden  lg:hidden md:visible h-[334px] w-[40%] object-cover md:w-full md:order-2 md:mt-[20px] "
                />
                <Text as="p" className="w-[90%]  !font-normal leading-7">
                  Establish a zero trust blockchain ecosystem based on Proof of
                  Machine Trust (PoMT) consensus mechanism, Artificial
                  Intelligence, Decentralized KYC, Decentralized Smart Automated
                  Engine (D-SAE) as a fiduciary and Decentralized Smart
                  Automated Hub (D-SAH) for investors/ stockholders to
                  store/stake money/token through smart contract under the
                  governance of D-AFI without human participation.
                </Text>
              </div>
            </div>
          </div>

          {/* team section */}
          <div className="sm:w-full w-4/5 flex flex-col items-center justify-center bg-white-A700_0c pb-32 md:py-5">
            <div className="w-full flex justify-center items-center sm:pb-2 md:pb-10 pb-20">
              <Heading as="h3">Meet our team</Heading>
            </div>

            {/* meet our team cards */}
            <div className="container-xs flex justify-center items-center gap-8 md:flex-col md:p-5 p-0">
              <div className="w-full md:w-3/4 flex md:justify-center justify-between items-center sm:gap-4 md:gap-12 lg:gap-auto md:flex-wrap flex-row lg:px-6">
                {data.map((d, index) => (
                  <AboutUsCard {...d} key={"aboutSection" + index} />
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* footer section */}
        <Footer />
      </div>
    </>
  );
}
