import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Heading, Button, Text} from "../../components";
import Header from "../../components/Header";
import {useState, useEffect} from 'react';




/* Countdown function */



export default function TestingPage() {
 
  /* Countdown function */
  const countdownDate = new Date('11/01/2024');
  //end date
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
//state variable to store days, hours, minutes and seconds

  useEffect(() => {
    const interval = setInterval(() => setNewTime(), 1000);
    //indicating function to rerun every 1000 milliseconds (1 second)

    if(state.seconds < 0){
      clearInterval(interval)
    //Stop the rerun once state.seconds is less than zero
    }
  },);

  const setNewTime = () => {
    if (countdownDate) {
      const currentTime = new Date();
      //get current time now in milliseconds
      const distanceToDate = countdownDate - currentTime;
      //get difference dates in milliseconds
      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      // get number of days from the difference in dates
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      // get number of minutes from the remaining time after removing hours
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
      );
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);
      // number of hours from the remaining time after removing seconds

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      days = `${days}`;
      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }
      // a logic to add 0 in front of numbers such that 1 will be 01, 2 will be 02, and so on.

      setState({ days, hours, minutes, seconds });
      //Updating the state variable.
    }
  };



  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
    <div className="flex w-full flex-col items-center bg-white-A700">
      <div className="flex flex-col items-center justify-center sm:gap-[20px] md:gap-[40px] gap-[60px] self-stretch bg-white-A700 pb-[132px] md:pb-5">
          <Header className="self-stretch" />


      <div className="container-sm flex w-full flex-col items-center gap-11 bg-white-a700 mt-[-110px]">
        <div className="mx-auto  flex w-full max-w-[1442px] flex-col items-end gap-[26px] self-stretch lg:px-5 md:px-5">
          <div className="flex flex-col gap-6 self-stretch">
          <Text className="text-center sm:text-3xl md:text-4xl text-4xl  font-semibold sm:leading-9 md:leading-[50px] leading-[74px] !text-gray-900_03">
              Be a Part of 100% Decentralized Quickall Technology
            </Text>
            <Text className="text-xl leading-8 !text-gray-900 mt-[-20px]">
              
                Quickall Al-driven blockchain announces campaign to barter money with native G coin Barter Certificate
                as a commodity, G coin 
                concept is registered in the US Copyright Office              
            </Text>
            <div className="flex self-start bg-gray-900_03 mt-[-20px]">
              <Text size="textxs" as="h3" className="!text-white-A700">
                &nbsp; Registration Number: TXu 2-422-204  &nbsp;
              </Text>
            </div>
          </div>
        </div>
      
          <div className="flex p-1 mt-[-30px]">
          <Link
                  to="/Home1"
                  style={{ color: "#ffffff", textDecoration: "none" }}
                >
            <Button shape="round" size="text-2xl" as="h3" className="bg-teal-900 !text-white-A700 ml-auto h-12">
            <Text className="text-xl !text-white-A700">
            &nbsp;&nbsp;Join the Quickall Blockchain Unique Bartering Process!&nbsp;&nbsp;
            </Text>
            </Button>
          </Link>
          </div>
        
        <div className="flex self-stretch md:flex-col">
          <div className="flex h-[280px] w-full items-start justify-center bg-[url(/public/images/img_group_38.svg)] bg-cover bg-no-repeat px-3.5 py-[84px] lg:h-auto lg:py-8 md:h-auto md:p-5 sm:px-5 sm:py-4">
            <div className="mb-[54px] flex w-[90%] flex-col items-center gap-[100px] lg:w-full lg:gap-[150px] md:w-full md:gap-[211px] sm:gap-[141px]">
            <Text
                
                className="text-xl w-full leading-8 !text-white-A700 md:text-[30px] sm:text-[28px]"
              >
                <>
                Secure your exclusive GGQ Coin Certificate—Platinum, Gold, or Silver—today and 
                unlock extra rewards and benefits!
                </>
              </Text>
              <Text
                
                className="text-xl w-full leading-1 !text-white-A700 md:text-[5px] sm:text-[28px] mt-[-100px]"
              >
                <>
                Invite your friends or community to join and earn 500 CCQ coins for each 
                successful referral. Start sharing today.
                </>
              </Text>
              <Text className="text-xl w-full leading-1 !text-white-A700 md:text-[5px] sm:text-[28px] mt-[-100px]"
              >
                <>
                Apply for your Platinum, Gold, or Silver Certificate by clicking the green 
                Barter Certificate button and completing the application form. Unlock your benefits today!
                                  
                </>
              </Text>
            </div>
          </div>
          <div className="flex h-[420px] w-full flex-col items-center gap-2 bg-gray-900_0c p-3 lg:gap-20 md:gap-[60px] md:px-5 sm:gap-5">
            <div className="relative h-[188px] w-[86%]">
            <Text className="!text-gray-900_03 text-center">NEXT PRICE INCREASE IN ...</Text>
                <div className="ml-[50px]" style={{ fontSize: '60px', fontWeight: 'bold' }}>
                  {`${state.days || '00'}  :  ${state.hours || '00'}  :  ${state.minutes || '00'}   :  ${state.seconds || '00'}`}
                </div>
                <div className="ml-[50px] mt-[-20px]" style={{ fontSize: '24px'}}>
                <span>&nbsp;&nbsp;Days &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>Hours&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>Mins &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>Secs</span>
                </div>
            </div>
            <div className="flex p-1 mt-[-40px]">
            <div className="flex self-start bg-gray-900_03 mt-[-20px]">
              <Text  className="text-4xl !text-white-A700">
              &nbsp;&nbsp;1 Quickall GGQ coin = 0.03 USD&nbsp;&nbsp;
              </Text>
            </div>
            </div>
            <div className="mr-1 flex w-[70%] flex-col items-center gap-3 lg:w-full md:mr-0 md:w-full mt-[-60px]">
              <div className=" h-[24px] w-[96%] flex items-center border border-solid border-black-900 bg-white-a700">
                <div className="h-[24px] w-[40%] bg-teal-900" />
              </div>
              <Text size="texts" as="p">
                Received USD from Bartering until today: $ 100,000
              </Text>
            </div>
            <Heading size="textxs" as="p" className="mb-2 mt-[-60px]">
              This is NOT an ICO or token Pre-sale
            </Heading>
            
          </div>
        </div>
      </div>
      </div>
    </div>
    </>
  );
}
