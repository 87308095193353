import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Heading, Button, Text, Img, Slider } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import HomeBlogcard from "../../components/HomeBlogcard";
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
  Accordion,
  AccordionItem,
} from "react-accessible-accordion";
import PreviousIcon from "../../assets/images/previous.png";
import NextIcon from "../../assets/images/next.png";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Input = React.forwardRef(({ className, ...props }, ref) => (
  <input
    ref={ref}
    {...props}
    className={`border border-solid rounded-lg h-[64px] px-[33px] text-base ${className}`}
  />
));


const questionsAndAnswers = [
  {
    question: "What is Quickall Blockchain?",
    answer: "Quickall is an AI-driven blockchain platform designed to enhance decentralization, security, and efficiency through its breakthrough Proof of Machine Trust consensus mechanism."
  },
  {
    question: "How does Quickall ensure security?",
    answer: "Quickall enhances security using AI-driven algorithms for real-time threat detection, quantum-resistant cryptography, and robust node verification to safeguard against vulnerabilities."
  },
  {
    question: "What is the Proof of Machine Trust (PoMT) consensus mechanism?",
    answer: "PoMT is a unique and game-changing consensus mechanism combining decentralized AI, and various consensus concepts to optimize transaction validation and ensure a decentralized, unbiased network."
  },
  {
    question: "How does Quickall address scalability issues?",
    answer: "Quickall uses Decentralized Artificial Fragmentary Intelligence (D-AFI) to dynamically adjust block sizes and transaction processing, maintaining high throughput especially during peak demand."
  },
  {
    question: "Can Quickall integrate with other systems?",
    answer: "Yes, Quickall simplifies integration with user-friendly, no-code smart contract development, comprehensive developer support, and seamless API access for various industry applications."
  },
  {
    question: "What industries can benefit from Quickall?",
    answer: "Quickall supports versatile applications across finance, healthcare, supply chain, and more, leveraging AI and blockchain for innovative and efficient solutions."
  }
];

const headingsAndTexts = [
  {
    heading: "Enhanced Security",
    text: "QUICKALL ensures enhanced security by integrating advanced AI algorithms that continuously monitor and adapt to potential threats, providing a robust defense mechanism."
  },
  {
    heading: "Versatile Applications",
    text: "With its flexible architecture, QUICKALL supports a wide range of applications from financial services to supply chain management, allowing seamless integration across industries."
  },
  {
    heading: "Decentralized AI Framework",
    text: "QUICKALL employs a decentralized AI framework that enables autonomous decision-making processes, reducing the need for centralized control and enhancing operational efficiency."
  },
  {
    heading: "Innovative Consensus Mechanism",
    text: "The innovative consensus mechanism of QUICKALL enhances transaction validation speed and ensures consensus integrity without compromising the decentralized nature of the blockchain."
  },
  {
    heading: "Scalability Solutions",
    text: "QUICKALL addresses scalability issues by using Decentralized Artificial Fragmentary Intelligence (D-AFI) to dynamically adjust block size and transaction processing, ensuring high throughput and efficiency."
  },
  {
    heading: "Seamless Integration",
    text: "QUICKALL provides seamless integration capabilities, allowing businesses to incorporate its blockchain solutions into existing systems effortlessly, minimizing disruption and enhancing functionality."
  }
];


export default function Home1Page() {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    query: ''
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await axios.post('http://134.122.118.11:3001/send-email', formData);
      if (response.status === 200) {
        setSuccessMessage('Your message has been sent successfully.');
        setFormData({ name: '', email: '', query: '' }); // Reset form fields
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>

      <div className="flex w-full flex-col items-center bg-white-A700">
        <div className="flex flex-col items-center justify-center sm:gap-[20px] md:gap-[40px] gap-[60px] self-stretch bg-white-A700 pb-[132px] md:pb-5">
          <Header className="self-stretch" />




          {/* Banner Section*/}

          <div className="container-sm flex items-start justify-between gap-5 sm:gap-1 md:gap-2 md:flex-col md:p-5">
            <div className="flex w-[48%] flex-col items-start sm:gap-[10px] md:gap-[25px] gap-[49px] md:w-full">
              <div className="flex flex-col gap-6 self-stretch">
                <Text className="sm:text-3xl md:text-4xl text-5xl  font-semibold sm:leading-9 md:leading-[50px] leading-[74px] !text-black-900">
                  Embrace Decentralized Future
                </Text>
                <Text
                  as="p"
                  className="w-[97%] !font-normal leading-7 !text-black-900_b2 md:w-full"
                >
                  with Innovative Blockchain, Machine Trust, and Decentralized AI
                </Text>
              </div>
              <Button
                shape="round"
                className="min-w-[216px] border-[3px] border-solid border-white-A700 font-medium sm:px-5 bg-gray-900_03"
              >
                <Link
                  to="/features"
                  style={{ color: "#ffffff", textDecoration: "none" }}
                >
                  Read More
                </Link>
              </Button>

            </div>

            <Img
              src="images/img_https_www_pex_336x547.webp"
              alt="featurehero"
              className="h-[409px] w-[55%] md:w-full"
            />

          </div>

          {/* End of banner*/}



        </div>
        <div className="flex justify-center self-stretch bg-gray-900_0c pb-[99px] pt-[124px] md:py-5">
          <div className="container-sm flex items-start justify-between gap-5 pl-[7px] pr-[45px] md:flex-col md:p-5 md:pr-5">
            <div className="flex w-[34%] flex-col items-start gap-3 md:w-full">
              <Text className="!text-black-900 font-semibold sm:text-3xl md:text-4xl text-5xl">
                What is Quickall?
              </Text>
              <Text as="p" className="w-[96%] !font-normal leading-7 md:w-full">
                QUICKALL is an AI-driven blockchain platform utilizing the Proof of Machine Trust consensus mechanism to revolutionize decentralized applications and industry solutions.
              </Text>
              <div className="flex items-center gap-5">
                <a href="/aboutus">
                  <Text size="md" as="p" className="!text-gray-900_03">
                    Read More
                  </Text>
                </a>
                <Img
                  src="images/img_arrow_2.svg"
                  alt="arrowtwo"
                  className="mb-[11px] h-px self-end"
                />
              </div>
            </div>

            {/*<div className="flex w-[55%] flex-col gap-px md:w-full">
              {data2.map((d, index) => (
                <div key={"cards" + index} className="flex w-[45%] flex-col gap-[22px] md:w-full">
                  <Text size="2xl" as="p" className="!text-gray-900_03">
                    {d.enhanced}
                  </Text>
                  <Text as="p" className="!font-normal leading-7">
                    Euismod faucibus turpis eu gravida mi. Pellentesque et velit aliquam .Euismod faucibus turpis eu
                    gravida mi. Pellentesque et velit aliquam .
                  </Text>
                </div>
              ))}
            </div>*/}
            <div className="mt-[5px] flex w-[59%] flex-col gap-[58px] md:w-full sm:gap-[29px] md:gap-[10px]">
              <div className="flex w-[92%] justify-between gap-5 md:w-full md:flex-col">
                <div className="flex w-[44%] flex-col items-start gap-[5px] md:w-full">
                  <Text size="md" as="p" className="!text-gray-900_03">
                    Enhanced Security
                  </Text>
                  <Text as="p" className="w-full !font-normal leading-7">
                    QUICKALL enhances security with AI-driven algorithms and quantum-resistant
                    cryptography, ensuring robust protection against evolving cyber threats.
                  </Text>
                </div>
                <div className="flex w-[47%] flex-col items-start gap-[5px] md:w-full">
                  <Text size="md" as="p" className="!text-gray-900_03">
                    Versatile Applications
                  </Text>
                  <Text
                    as="p"
                    className="w-[94%] !font-normal leading-7 md:w-full"
                  >
                    QUICKALL supports versatile decentralized applications across industries,
                    including finance, healthcare, education, agriculture, manufacturing,
                    supply chain, E-commerce, internet of things but not only these
                    and leveraging decentralized AI combined with blockchain for
                    breakthrough solutions.
                  </Text>
                </div>
              </div>
              <div className="flex gap-[41px] md:flex-col md:gap-[10px]">
                <div className="flex w-[46%] flex-col md:w-full">
                  <Text
                    size="md"
                    as="p"
                    className="leading-[48px] !text-gray-900_03"
                  >
                    Decentralized AI Framework
                  </Text>
                  <Text
                    as="p"
                    className="w-[92%] !font-normal leading-7 md:w-full"
                  >
                    QUICKALL's decentralized AI framework ensures unbiased, automated decision-making,
                    enhancing trust and efficiency across blockchain transactions without human intervention.
                  </Text>
                </div>
                <div className="flex flex-1 flex-col md:self-stretch">
                  <Text
                    size="md"
                    as="p"
                    className="leading-[48px] !text-gray-900_03"
                  >
                    Innovative Consensus Mechanism
                  </Text>
                  <Text
                    as="p"
                    className="w-[84%] !font-normal leading-7 md:w-full"
                  >
                    QUICKALL's innovative Proof of Machine Trust consensus
                    mechanism to make real decentralization without human intervention based on
                    automated decision-making consensus mechanism by Quickall machine-nodes.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:mt-[20px] md:mt-[40px] mt-[100px] flex w-[80%] flex-col gap-[55px] md:w-full md:p-5 sm:gap-[27px]">
          <div className="flex items-start justify-between sm:gap-1 md:gap-2 gap-5 md:flex-col">
            <Text className="!text-black-900 sm:text-3xl md:text-4xl text-5xl font-semibold sm:leading-7 md:leading-10 leading-12">
              Join Us as a Money Manager
            </Text>
            <div className="mt-[22px] flex items-center gap-4">
              <Text as="p" className="!text-gray-900_03">
                <Link
                  to="/plans"
                  className="whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  View more
                </Link>
              </Text>

              <Img
                src="images/img_arrow_2.svg"
                alt="arrowone"
                className="mb-2.5 h-px self-end"
              />
            </div>
          </div>
          <div className="flex gap-[23px] md:flex-col">
            <div className="flex h-[600px] flex-1 items-center bg-[url(/public/images/img_group_23.png)] bg-cover bg-no-repeat md:h-auto md:self-stretch">
              <div className="flex w-[52%] h-full flex-col justify-center gap-4 bg-gradient1 pb-36 pl-[45px] pr-[35px] pt-[200px] md:w-full md:py-5 md:pl-5 sm:p-5">
                <Text className="sm:text-3xl md:text-4xl text-5xl font-semibold sm:leading-7 md:leading-10 leading-12 !text-white-A700">
                  Shape the Future of Finance
                </Text>
                <Text
                  as="p"
                  className="w-[94%] leading-7 !text-white-A700_cc md:w-full"
                >
                  Join QuickAll with your money and assets. Trust our Fiduciary Service as your Money Manager.
                </Text>
              </div>
            </div>
            <div className="flex w-[33%] flex-col gap-8 md:w-full">
              <div className="relative h-[284px] w-full md:h-auto flex">
                <Img
                  src="images/img_rectangle_1313.png"
                  alt="image"
                  className="h-[284px] w-full"
                />
                <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-full w-full flex-col justify-center gap-[13px] bg-gradient1 pb-[45px] pl-14 pr-9 pt-[129px] md:py-5 md:pl-5 sm:p-5">
                  <Heading
                    size="xs"
                    as="h3"
                    className="leading-9 !text-white-A700"
                  >
                    <>
                      Quickall Fiduciary services will
                      provide you peace of mind when using
                      our Money Manager service.
                    </>
                  </Heading>
                  <div className="flex items-start gap-4">

                    <Img
                      src="images/img_arrow_2_amber_200.svg"
                      alt="arrowtwo"
                      className="mt-2.5 h-px"
                    />
                  </div>
                </div>
              </div>
              <Img
                src="images/img_card.png"
                alt="card"
                className="h-[284px] object-cover"
              />
            </div>
          </div>
        </div>

        {/* Blockchain that solves many existing problems Section*/}


        <div className="sm:mt-[15px] md:mt-[50px] mt-[124px] flex flex-col items-center justify-center sm:gap-2 md:gap-[40px] gap-[111px] self-stretch bg-gray-900_0c pb-32 pt-[168px] md:py-5">
          <div className="container-sm pl-[327px] pr-[323px] md:p-5 md:px-5">
            <Text className="text-center sm:text-3xl md:text-4xl text-5xl font-semibold sm:leading-7 md:leading-[50px] leading-[65px] !text-black-900">
              Blockchain that solves many existing problems.
            </Text>
          </div>


          <div className="container-sm grid grid-cols-3 justify-center gap-8 md:grid-cols-2 md:p-5 sm:grid-cols-1">
            {headingsAndTexts.map((item, index) => (
              <div
                key={`feature${index}`}
                className="flex w-full flex-col items-start justify-center gap-[39px] bg-white-A700 pb-[90px] pl-12 pr-8 pt-[38px] md:pb-5 md:pl-5 sm:p-5"
              >
                <Text size="lg" as="p" className="!text-gray-900_03">
                  {item.heading}
                </Text>
                <Text as="p" className="w-full !font-normal leading-7">
                  {item.text}
                </Text>
              </div>
            ))}
          </div>
        </div>

        <div className="flex w-full justify-center items-center self-stretch sm:my-3 my-[100px]">
          <div className="sm:w-full md:w-full w-4/5 bg=[#92b0ca] overflow-hidden">
            <Slider
              autoPlay
              autoPlayInterval={2000}
              responsive={{
                0: { items: 1 },
                550: { items: 1 },
                1050: { items: 1 },
              }}
              disableDotsControls
              activeIndex={sliderState}
              onSlideChanged={(e) => {
                setSliderState(e?.item);
              }}
              ref={sliderRef}
              items={[...Array(3)].map(() => (
                <React.Fragment key={Math.random()}>
                  <div className="flex bg-gray-100_7f py-32 md:py-5">
                    <div className="container-sm flex items-start justify-center gap-[31px] md:flex-col md:p-5">
                      <div className="flex w-[33%] flex-col gap-4 md:w-full">
                        <Heading
                          size="s"
                          as="h2"
                          className="leading-[56px] !text-black-900"
                        >
                          What industry experts say about us
                        </Heading>
                        <Text
                          as="p"
                          className="w-[81%] !font-normal leading-7 md:w-full"
                        >
                          Macro
                        </Text>
                      </div>
                      <div className="flex flex-1 flex-col gap-[51px] md:self-stretch sm:gap-[25px]">
                        <Text
                          size="2xl"
                          as="p"
                          className="leading-[48px] !text-blue_gray-900_01"
                        >
                          <span className="text-blue_gray-900_01">&quot;</span>
                          <span className="text-black-900 sm:text-2xl">
                            QuickAll's innovative AI-driven blockchain is revolutionary idea for our operations with its decentralized and automated solutions.
                            Truly a game-changer for our industry!&quot;
                          </span>
                        </Text>
                        <div className="flex items-start justify-between gap-5 sm:h-[50px]">
                          <div className="flex w-full items-center justify-start gap-3.5">
                            <Img
                              src="images/img_https_www_pex_168x168.png"
                              alt="httpswwwpex"
                              className="h-[52px] w-[52px] rounded-[50%]"
                            />
                            <div className="flex flex-col gap-0.5 self-start">
                              <Text
                                size="md"
                                as="p"
                                className="!text-blue_gray-900_01"
                              >
                                Abby Gorge
                              </Text>
                              <Text
                                size="xs"
                                as="p"
                                className="!text-blue_gray-900_01"
                              >
                                Blockchain Expert
                              </Text>
                            </div>
                          </div>
                          <div className="flex gap-[10px] h-full flex-1">
                            <button
                              shape="circle"
                              onClick={() => {
                                sliderRef?.current?.slidePrev();
                              }}
                              className="w-[48px] !rounded-[24px] p-0"
                            >
                              <img
                                className="h-full w-full"
                                src={PreviousIcon}
                                height={15}
                                width={15}
                                alt="..."
                              />
                            </button>
                            <button
                              size="md"
                              shape="circle"
                              onClick={() => {
                                sliderRef?.current?.slideNext();
                              }}
                              className="w-[48px] !rounded-[24px] p-0"
                            >
                              <img
                                className="h-full w-full"
                                src={NextIcon}
                                height={15}
                                width={15}
                                alt="..."
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            />
          </div>
        </div>
        <div id="FAQ" className="sm:mt-10 md:mt-12 mt-20 flex md:w-full w-[80%] items-start justify-center md:flex-col md:p-5">
          <div className="flex w-[26%] flex-col items- md:items-center gap-[17px] md:w-full">
            <Text className="sm:text-3xl md:text-4xl text-5xl font-semibold sm:leading-7 md:leading-10 leading-[56px] text-[#000]">
              Frequently asked questions
            </Text>

            <Text size="md" as="p" className="!text-gray-900_dd">
              <Link
                to="/contactus"
                className="whitespace-nowrap"
                style={{ textDecoration: "none" }}
              >
                Contact us for more information
              </Link>
            </Text>
          </div>

          <Accordion
            preExpanded={[0]}
            className="md:mt-3 mt-1.5 md:ml-0 ml-[35px] flex flex-1 flex-col md:gap-2 md:w-full px-6 md:px-0"
          >
            {questionsAndAnswers.map((item, index) => (
              <AccordionItem uuid={index} key={`question${index}`}>
                <div className="ml-[27px] flex flex-1 flex-col items-center gap-3.5 md:ml-0 py-5">
                  <AccordionItemHeading className="w-full">
                    <AccordionItemButton>
                      <AccordionItemState>
                        {(props) => (
                          <>
                            <div className="flex md:items-start items-center justify-between gap-5 self-stretch flex-nowrap">
                              <div className="flex w-[72%] flex-wrap justify-between gap-5 md:w-full">
                                <Text
                                  size="lg"
                                  as="p"
                                  className="self-start !text-gray-900_03"
                                >
                                  {String(index + 1).padStart(2, '0')}
                                </Text>
                                <Text
                                  size="lg"
                                  as="p"
                                  className="flex-1 !text-blue_gray-900_01"
                                >
                                  {item.question}
                                </Text>
                              </div>
                              {props?.expanded ? (
                                <Img
                                  src="images/img_close.svg"
                                  alt="close"
                                  className="h-[22px] w-[22px] font-bold"
                                />
                              ) : (
                                <Img
                                  src="images/img_plus.svg"
                                  alt="plus"
                                  className="h-[22px] w-[22px] font-semibold"
                                />
                              )}
                            </div>
                          </>
                        )}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="w-[76%] md:w-full pl-10">
                      <Text
                        size="md"
                        as="p"
                        className="leading-8 !text-blue_gray-900_dd"
                      >
                        {item.answer}
                      </Text>
                    </div>
                  </AccordionItemPanel>
                </div>
                <div className="h-[2px] w-full rotate-[0deg] bg-blue_gray-50" />
              </AccordionItem>
            ))}
          </Accordion>

        </div>


        {/* Send Inquiry Section*/}
        <div className="sm:mt-[15px] md:mt-[50px] mt-[124px] flex flex-col items-center justify-center sm:gap-2 md:gap-[40px] gap-[40px] self-stretch bg-gray-900_0c pb-32 pt-[32px] md:py-5">
          <div className="container-sm relative mt-[125px] h-[692px] md:p-5 overflow-hidden">
            <Img
              src="images/img_https_www_pex_336x547.webp"
              alt="imagetwentyfive"
              className="absolute bottom-0 left-0 top-0 my-auto md:h-full h-[746px] md:w-full w-[54%] object-cover"
            />
            <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex md:h-full h-max w-full justify-end bg-gray-900_7f">
              <div className="flex w-[91%] items-start justify-between md:gap-1 gap-5 md:w-full md:flex-col md:p-5">
                <div className="md:mt-0 mt-24 flex w-[40%] flex-col md:gap-2 gap-6 md:w-full">
                  <Text className="sm:text-3xl md:text-4xl text-5xl font-semibold sm:leading-7 md:leading-10 leading-[74px] !text-white-A700">
                    Building a Blockchain for every business.
                  </Text>
                  <Text
                    as="p"
                    className="leading-7 !text-white-A700 md:mb-[15px]"
                  >
                    Quickall provides a blockchain ecosystem driven by decentralized AI, designed for every business, delivering secure,
                    efficient, and scalable solutions to meet diverse industry needs.
                  </Text>
                </div>

                <div className="flex md:h-[80%] h-full w-[53%] flex-col items-start justify-center bg-white-A700 pb-[108px] pl-24 pr-14 pt-[98px] md:w-full md:p-5">
                  <Text
                    size="2xl"
                    as="p"
                    className="!font-normal !text-gray-900_03"
                  >
                    Send Inquiry
                  </Text>
                  <Text
                    as="p"
                    className="mt-[13px] w-[92%] leading-7 !text-gray-900_03 md:w-full"
                  >
                    Contact Quickall for inquiries about our blockchain solutions driven by decentralized AI.
                  </Text>


                  <form className="md:mt-2 mt-10 flex w-[92%] flex-col !text-gray-100 gap-4 md:w-full" onSubmit={handleSubmit}>
                    <Input
                      className="md:h-12 !text-gray-900_03 flex items-center justify-center cursor-text text-base border border-solid rounded-lg  rounded-lg [object Object] h-[64px] px-[33px] text-base"
                      shape="round"
                      color="!text-gray-900_03"
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                    <Input
                      className="md:h-12 !text-gray-900_03 flex items-center justify-center cursor-text text-base border border-solid rounded-lg  rounded-lg [object Object] h-[64px] px-[33px] text-base"
                      shape="round"
                      color="!text-gray-900_03"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <Input
                      className="md:h-12 !text-gray-900_03 flex items-center justify-center cursor-text text-base border border-solid rounded-lg  rounded-lg [object Object] h-[64px] px-[33px] text-base custom-input"
                      shape="round"
                      color="!text-gray-900_03"
                      name="query"
                      placeholder="Write your query"
                      value={formData.query}
                      onChange={handleChange}
                      required
                    />

                    <div className="md:w-full w-fit flex justify-center items-center">
                      <button
                        type="submit"
                        className="sm:mt-[20px] mt-[39px] sm:min-w-[300px] sm:h-[45px] min-w-[432px] !rounded-[32px] font-manrope font-semibold sm:px-5 bg-gray-900_03 flex flex-row items-center justify-center text-center cursor-pointer rounded-[30px] h-[66px] px-[35px] text-lg "
                        disabled={loading}
                      >
                        {loading ? 'Sending...' : 'Send'}
                      </button>
                    </div>

                    {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
                    {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
                  </form>

                  {/*<Button shape="round" className="min-w-[216px] border-[3px] border-solid border-white-A700 font-medium sm:px-5 bg-gray-900_03">
                <Link to="/bartercertificate" style={{ color: "#ffffff", textDecoration: "none" }}>
                Send an Inquiry
                </Link>
                </Button>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-sm sm:mt-[10px] md:mt-[30px] mt-[129px] flex flex-col items-start gap-[54px] md:p-5 sm:gap-[27px] mb-[50px]">
          <Text className="sm:text-3xl md:text-4xl text-5xl font-semibold sm:leading-7 md:leading-10 leading-[50px] text-[#000]">
            Our blog
          </Text>



          <div className="grid grid-cols-3 justify-center gap-8 self-stretch md:grid-cols-2 sm:grid-cols-1">
            <div className="flex w-full flex-col items-start">
              <Img
                src="images/blog5_square.webp"
                alt="27_jan_2024"
                className="h-[285px] w-full object-cover md:h-auto"
              />
              <Text as="p" className="mt-[34px]">
                July 18, 2024
              </Text>
              <Link
                to="/blogpage"
                style={{ color: "ffffff", textDecoration: "none" }}
                className="w-full leading-9"
              >
                <Text size="lg" as="p" className="!text-gray-900_03">
                  Enhancing Efficiency and Security in Logistics and Transportation with Quickall Blockchain
                </Text>
              </Link>
              <div className="mt-4 flex flex-col self-stretch">
                <Text as="p" className="relative z-[1] !font-normal leading-7">
                  The efficiency and security of logistics and transportation systems controlled centrally are ..
                </Text>
                <div className="relative mt-[-1px] flex items-center gap-3">
                  <Link
                    to="/blogpage"
                    style={{ color: "ffffff", textDecoration: "none" }}
                    className="!text-gray-900_03">
                    Read More

                  </Link>

                </div>
              </div>
            </div>
            <div className="flex w-full flex-col gap-[34px]">
              <Img src="images/blog4_square.webp" alt="imageeleven" className="h-[285px] object-cover" />
              <div className="flex flex-col items-start gap-4">
                <Text as="p">July 11, 2024 </Text>

                <Link
                  to="/blogpage"
                  style={{ color: "ffffff", textDecoration: "none" }}
                  className="w-full leading-9"
                >


                  <Text size="lg" as="p" className="!text-gray-900_03">
                    How Quickall's Blockchain Will Enhance Overseas Remittance and Help in Banking Operations
                  </Text>
                </Link>
                <div className="flex flex-col self-stretch">
                  <Text as="p" className="!font-normal leading-7">
                    Efficiency and security are of the utmost importance in the financial sector, particularly in the realm of banking
                  </Text>
                  <div className="relative mt-[-1px] flex items-center gap-3">
                    <Link
                      to="/blogpage"
                      style={{ color: "ffffff", textDecoration: "none" }}
                    >
                      <Text as="p" className="!text-gray-900_03">
                        Read More
                      </Text>
                    </Link>
                    <Img src="images/img_arrow_2.svg" alt="arrowone" className="h-px" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col items-start">
              <Img
                src="images/blog3_banner.webp"
                alt="httpswwwpex"
                className="h-[285px] w-full object-cover md:h-auto"
              />
              <Text as="p" className="mt-[29px]">
                June 7, 2024
              </Text>
              <Link
                to="/blogpage"
                style={{ color: "ffffff", textDecoration: "none" }}
                className="w-full leading-9"
              >
                <Text size="lg" as="p" className="!text-gray-900_03">
                  <span className="text-gray-900_03">
                    QuickAll: Building a Decentralized Blockchain Compliant with SEC Regulations
                  </span>
                  <span className="text-gray-900_03"></span>
                </Text>
              </Link>
              <div className="mt-4 flex flex-col self-stretch">
                <Text as="p" className="!font-normal leading-6">
                  The regulatory compliance and centralization of the cryptocurrency sector are the subject of intense scrutiny.
                </Text>
                <div className="relative mt-[-1px] flex items-center gap-3">
                  <Link
                    to="/blogpage"
                    style={{ color: "ffffff", textDecoration: "none" }}
                    className="w-full leading-9"
                  >
                    <Text as="p" className="!text-gray-900_03">
                      Read More
                    </Text>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/*} <div className="flex gap-8 self-stretch md:flex-col">
            {data1.map((d, index) => (
              <HomeBlogcard {...d} key={"list19Apr2024" + index} />
            ))}
          </div> */}

        </div>
        <Footer className="mt-[129px] self-stretch" />
      </div>
      <style jsx>{`.custom-input {
  border: none; 
      }
  .custom-input:focus  {
  border: none; /* Remove border on hover */
  outline: none;
}
`}</style>
    </>
  );
}
