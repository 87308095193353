import React from "react";
import { Helmet } from "react-helmet";
import { Text, Heading } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function PrivacyPolicyPage() {
  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full flex-col items-center gap-5 bg-white-A700 md:gap-24 sm:gap-16">
        <Header className="self-stretch" />
        <div className="container-sm pl-[75px] pr-[75px] md:p-5 md:px-5">
          <div className="flex flex-col items-center gap-[25px] sm:gap-[33px]">
            <div className="flex w-[67%] flex-col items-center gap-[7px] md:w-full"></div>
        
            <Heading as="h1"> Privacy Policy </Heading>
          
          <Text as="p" className="w-full text-center !font-normal  "><strong>Most recent revision: June 20th, 2024</strong></Text>
 
         <div className="flex flex-col items-start self-stretch">
             <Text as="p" className="mt-3 w-full !font-normal leading-7"> &nbsp;Your privacy rights and the ways in which the law protects you are discussed in this Privacy Policy, which also provides information on the policies and procedures that we have in place regarding the collection, use, and sharing of your information when you use the Service.&nbsp;</Text>
             <Text as="p" className="mt-3 w-full !font-normal leading-7"> &nbsp;Your personal information is utilized by us in order to offer and enhance the Service. You are indicating that you are willing to consent to the collection and use of information in line with this Privacy Policy by using the Service.&nbsp;</Text>
          </div>  
             <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>Definitions, Interpretations, and Explanation of terms&nbsp;</strong></Text>
             <Text as="p" className="mt-3 w-full !font-normal leading-7"> &nbsp;The meanings of the words that have the initial letter capitalized are defined according to the criteria that are being discussed below. The following definitions are to be understood in the same manner regardless of whether they are presented in the singular or the plural form or both.</Text>
             <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>Explanations</strong></Text>
               <Text as="p" className="mt-3 w-full !font-normal leading-7"> &nbsp;In accordance with the objectives of this Privacy Policy:&nbsp;</Text>

  <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>Account</strong> refers to a one-of-a-kind account that is established specifically for you in order to access our services or sections of our service.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>Company</strong> (which may be referred to as &quot;the Company,&quot; &quot;We,&quot; &quot;Us,&quot; or &quot;Our&quot; throughout the provisions of this Agreement) is a reference to Quickall Finance Inc., USA.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>Cookies</strong> are little files that are automatically installed on your computer, mobile device, or any other device by a website. These cookies hold information about your browsing history on that website, which is just one of the many purposes for cookies.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>Country</strong> refers to the state of California, USA.&nbsp;</Text>
 
  <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>Device&nbsp;</strong>refers to any electronic device that is capable of accessing the Service, such as a computer, a mobile phone, or a digital tablet.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>Personal data</strong> refers any information that pertains to an individual who may be identified or identifiable.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>Service</strong> is referred to as the Website.</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> What is meant by the term <strong>&quot;Service Provider&quot;</strong> is any anyone, whether natural or legal, who processes the data on behalf of the Company. Third-party firms or persons who are employed by the Company to facilitate the Service, to deliver the Service on behalf of the Company, to perform services relating to the Service, or to assist the Company in assessing how the Service is used are referred to as third-party service providers.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>Third-party social media services</strong> are any website or social network website that allows a user to log in or create an account in order to access the service. These services are also known as social media platforms.&nbsp; &nbsp; <strong>Utilization Data</strong> is a term that describes the data that is gathered automatically, either as a result of the utilization of the Service or as a result of the infrastructure of the Service itself (for instance, the length of time that a page is visited).&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>QUICKALL</strong> is referred to on the website, which may be accessed at www.quickall.com.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> The term&nbsp;<strong>&quot;you&quot;</strong> refers to the person who is accessing or using the Service, or the company or other legal entity on behalf of which such a person is accessing or using the Service, depending on the circumstances.&nbsp; &nbsp; </Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>Obtaining and Making Use of Your Personal Information&nbsp;</strong></Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>Different kinds of information that are gathered&nbsp;</strong></Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong><em>Information of a Personal Nature&nbsp;</em></strong></Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> &nbsp;We may ask you to provide us with certain personally identifying information while you are using our service. This information can be used to identify you or contact you in the future. Information that can be used to identify a specific individual may include, but is not limited to:&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7">
<ul  >
    <li><span  > &bull;&nbsp; &nbsp; &nbsp;Email Address</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;First and the last name</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;Mobile number&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;Your address, state, province, postal code, and city.&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;Usage Data</span></li>
</ul>
</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong><em>The collection of usage data occurs automatically whenever the Service is utilized.</em></strong></Text>
   
  <Text as="p" className="mt-3 w-full !font-normal leading-7">Usage Data may comprise information such as the Internet Protocol address (also known as an IP address) of your device, the type of browser you use, the version of your browser, the pages of our service that you visit, the time and date of your visit, the amount of time you spend on those pages, unique device identifiers, and other diagnostic data.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> &nbsp;When you access the Service by or through a mobile device, we may automatically collect certain information. This information may include, but is not limited to, the type of mobile device you use, the unique ID of your mobile device, the IP address of your mobile device, the operating system of your mobile device, the type of mobile Internet browser you use, unique device identifiers, and other diagnostic data.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> &nbsp;Additionally, we may collect information that is transmitted by your browser whenever you visit our Service or whenever you access the Service by or through a mobile device.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>Technologies that track the user and cookies&nbsp;</strong></Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> &nbsp;Tracking the activity on Our Service and storing certain information is accomplished through the utilization of Cookies and other similar tracking technologies. It is possible to collect and track information, as well as develop and analyze Our Service, through the utilization of tracking technologies such as beacons, tags, and scripts. Our use of technologies may involve the following:&nbsp;</Text>
   
  <Text as="p" className="mt-3 w-full !font-normal leading-7">
<ul  >
    <li><em><span  ><strong> &bull;&nbsp; &nbsp; &nbsp;Cookies or browser cookies</strong></span></em><span  >. A little file that is stored on your device is known as a cookie. You have the ability to teach your browser to either show a warning when a cookie is being delivered or to explicitly refuse all cookies. On the other hand, if you do not consent to the use of cookies, you might not be able to access certain aspects of our service. To the extent that you have not modified the settings of your browser to prevent it from accepting cookies, our service may make use of cookies.&nbsp;</span></li>
    <li><em><span  ><strong> &bull;&nbsp; &nbsp; &nbsp;Flash Cookies.</strong></span></em><span  >&nbsp;Particular aspects of our service may make use of locally stored objects, also known as Flash Cookies, in order to gather and store information regarding your preferences or the activities you engage in while using our service. The browser settings that are used to manage browser cookies are not the same as those that are used to manage Flash Cookies. Please refer to the article titled &quot;Where can I change the settings for disabling or deleting local shared objects?&quot; for additional information on how you can delete Flash Cookies.The information may be found at the following location: https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling_or_deleting_local_shared_objects_&nbsp;</span></li>
    <li><em><span  ><strong>&bull;&nbsp; &nbsp; &nbsp;Web beacons</strong></span></em><span >. There is a possibility that certain parts of our Service and our emails may contain small electronic files known as web beacons. These web beacons are also known as clear gifs, pixel tags, and single-pixel gifs. They allow the Company to, for instance, count the number of users who have visited those pages or opened an email. Additionally, they can be used for other website statistics, such as recording the popularity of a particular section and verifying the integrity of the system and server.&nbsp;</span></li>
</ul>
   </Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7">&apos;Persistent&apos; cookies and &apos;Session&apos; cookies are both types of cookies. Persistent cookies are stored on your personal computer or mobile device even when you are not connected to the internet, whereas session cookies are removed from your computer as soon as you close your web browser.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> &nbsp;For the purposes that are outlined below, we make use of both session cookies and persistent cookies.&nbsp;</Text>
   
<div  >
    <ul  >
        <li  ><span  ><strong> &bull;&nbsp; &nbsp; &nbsp;The Cookies That Are Required or are Important&nbsp;</strong></span></li>
    

 <Text as="p" className="mt-3 w-full !font-normal leading-7">Type: Session Cookies</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">Administered by: US&nbsp;</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">Purpose: These cookies are necessary in order to provide you with the services that are accessible through the website and to make it possible for you to make use of some of the features that it offers. Authentication of users and the prevention of fraudulent usage of user accounts are both facilitated by them. If we do not have these Cookies, we will not be able to provide the services that you have requested, and we will only use these Cookies in order to provide those services to you.&nbsp;</Text>
 </ul>

    <ul  >
        <li  ><span  ><strong> &bull;&nbsp; &nbsp; &nbsp;Policies and Notices Regarding Cookies Acceptance Cookies</strong></span></li>
   

 <Text as="p" className="mt-3 w-full !font-normal leading-7">Type: Persistent Cookies</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">Administered by: Us</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">Purpose: In order to determine whether or not users have consented to the usage of cookies on the website, certain cookies are stored.&nbsp;</Text>
  
 </ul>
    <ul  >
        <li  ><span  ><strong> &bull;&nbsp; &nbsp; &nbsp;Cookies with Functionality&nbsp;</strong></span></li>
   
 <Text as="p" className="mt-3 w-full !font-normal leading-7">Type: Persistent Cookies</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">Administered by: Us</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">Purpose: The purpose of these cookies is to enable us to remember the choices that you make when you use the website, such as remembering your login information or your preferred language. You will not be required to re-enter your preferences each time you use the website because these cookies are designed to make your experience more personalized and to prevent you from having to do so individually.&nbsp;</Text>
  
  <Text as="p" className="mt-3 w-full !font-normal leading-7">We ask that you please visit either our Cookies Policy or the Cookies section of our Privacy Policy in order to obtain additional information regarding the cookies that we employ and the options that you have regarding cookies.&nbsp;</Text>
   
  <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>Usage of Your Individual Information</strong></Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7">The following are some circumstances in which the Company may make use of personal data:&nbsp;</Text>
  
  </ul>

   </div>
<ul>
    <li><span>&bull;&nbsp; &nbsp; &nbsp;For the purpose of providing and maintaining our Service, which includes monitoring how our Service is being used.&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;In order to manage your account, you will need to manage your registration as a provider of the Service. It is possible that the Personal Data that you provide will grant you access to various functionalities of the Service that are accessible to you as a registered user.</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;In the context of the performance of a contract, the development, compliance, and undertaking of the purchase contract for the products, items, or services that you have purchased, as well as any other contract with Us that is conducted through the Service.&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;To contact You: To contact You through electronic mail, telephone calls, short message service (SMS), or other equivalent forms of electronic communication, such as push notifications from a mobile application regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when it is necessary or reasonable for their implementation.&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;The purpose of this communication is to provide you with news, special offers, and general information about other products, services, and events that we offer that are comparable to those that you have already purchased or inquired about, unless you have specifically chosen not to receive such information.&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;The purpose of this is to attend to and manage your requests that have been submitted to us.&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;For business transfers: We may use Your information to evaluate or carry out a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or other similar proceedings, in which Personal Data held by Us about our Service users is among the assets transferred. This may be done in order to transfer some or all of Our assets.&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;Your information may also be used for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving our service, products, services, marketing, and your experience. Our use of your information may also be for other purposes.&nbsp;</span></li>
</ul>
   
  <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>In the following circumstances, we may disclose your personal information to third parties:&nbsp;</strong></Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> Within the context of Service Providers: We may disclose your personal information to Service Providers in order to monitor and analyze how you use our service, as well as to get in touch with you.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> Regarding business transfers: We may share or transfer your personal information in connection with any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company, or during negotiations of any of these transactions.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> Regarding Affiliates: We may share Your information with Our Affiliates; in the event that we do so, we will require those Affiliates to abide by this Privacy Policy. Our parent company and any other subsidiaries, joint venture partners, or other businesses that we control or that are under common control with us are considered to be our affiliates.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> With our business partners: We may share your information with our business partners in order to provide you with specific products, services, or promotions.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> When you share personal information or otherwise interact in public areas with other users, that information may be viewed by all users and may be publicly distributed outside of the site. Examples of this include when you share information with other users. If you interact with other users or register through a Third-Party Social Media Service, the people who are connected to you on that service may be able to view your name, profile, pictures, and a description of the activities you engage in. The same goes for other users; they will be able to view descriptions of your activity, communicate with you, and view your profile.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> We reserve the right to disclose any of your personal information for any other purpose on the condition that you give us permission to do so.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>Keeping Your Personal Information in Storage&nbsp;</strong></Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  ></span>For the purposes outlined in this Privacy Policy, the Company will only keep your personal information for the minimum amount of time required to fulfill those purposes. We will keep and use your personal information to the extent that is required to fulfill our legal obligations (for instance, if we are required to keep your data in order to comply with applicable laws), to resolve any disputes that may arise, and to enforce our legal agreements and policies against any violations that may occur.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  ></span>Additionally, the Company will keep Usage Data for the purposes of conducting internal analysis. Usage Data is typically kept for a shorter period of time, with the exception of situations in which this data is utilized to enhance the functionality of Our Service or to strengthen the security of Our Service, or when We are legally obligated to keep this data for longer time periods.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>The Transfer of Your Individual Information&nbsp;</strong></Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> The Company&apos;s operating offices, as well as any other locations where the parties involved in the processing are located, are the locations where your information, including Personal Data, is processed. It indicates that this information may be transferred to computers located outside of your state, province, country, or any other governmental jurisdiction where the data protection laws may be different from those in your jurisdiction. Additionally, it may be maintained on computers located in other such jurisdictions. &nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  ></span>Your agreement to the transfer of such information is represented by your consent to this Privacy Policy, which you then followed up with by submitting the information in question.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> No transfer of your personal data will take place to an organization or a country unless there are adequate controls in place, including the security of your data and other personal information. The Company will take all reasonable steps necessary to ensure that your data is treated securely and in accordance with this Privacy Policy. In addition, the Company will take all steps that are reasonably necessary to ensure that your data is treated effectively.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>Disclosure of All of Your Private Information&nbsp;</strong></Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> Transactions in the Business World&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> Your personal information may be transferred to a third party in the event that the Company is involved in a merger, acquisition, or sale of assets. A notification will be provided to you prior to the transfer of your personal data, which will then be subject to a different Privacy Policy.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> The enforcement of laws&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> If the Company is required to disclose Your Personal Data by law or in response to valid requests made by public authorities (such as a court or a government agency), then the Company may be required to do so under certain circumstances.&nbsp;</Text>
<ul  >
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;Various other legal prerequisites&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;Your personal information may be disclosed by the Company if it is in good faith belief that such action is required to:&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;Act in accordance with a legal requirement&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;Ensure that the Company&apos;s rights and property are safeguarded and protected.&nbsp; &nbsp;Take measures to prevent or investigate any potential violations of the Service&apos;s terms and conditions.&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;For the sake of the users of the service or the general public, ensure their personal safety.&nbsp;</span></li>
    <li><span  >&bull;&nbsp; &nbsp; &nbsp;For the purpose of avoiding legal liability.&nbsp;</span></li>
</ul>
   
  <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>The Protection of Your Private Information&nbsp;</strong></Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> &nbsp;It is important to us that your personal information is kept secure; however, it is important to keep in mind that there is no method of transmission over the internet or method of electronic storage that is completely secure. Although we make every effort to protect your personal information using methods that are acceptable to the business world, we are unable to guarantee that it is completely secure.&nbsp;</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">Depending on where you are located, you may have certain rights in connection with your personal information that we obtain. These rights vary depending on your jurisdiction. To learn more about your rights, see:</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">
<ul  >
    <li  >&bull;&nbsp; &nbsp; &nbsp;Appendix A for CCPA rights if you are a California resident.</li>
    <li  >&bull;&nbsp; &nbsp; &nbsp;Appendix B for GDPR rights if you are located in the EU, UK, or Switzerland.</li>
</ul>
</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>The Confidentiality of Children</strong>&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> Any person who is younger than 16 years old is not addressed by our service. Under no circumstances do we knowingly collect personally identifiable information from individuals who are younger than 16 years old. Please get in touch with us if you are a parent or guardian and you are aware that your child has provided us with personal information. In the event that we become aware that we have collected personal information from individuals under the age of 16 without first obtaining the express consent of their parents, we will take the necessary steps to remove that information from our servers.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> In the event that We are required to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require the consent of Your parent before we collect and use that information.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>URLs that lead to other websites&nbsp;</strong></Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> There is a possibility that our service will include links to other websites that are not managed by us. In the event that you click on a link belonging to a third party, you will be taken to the website of that third party. It is highly recommended that you read the Privacy Policy of each and every website that you make use of.&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> We do not have any control over any third-party websites or services, and we do not accept any responsibility for the content, privacy policies, or conduct of those websites or services.&nbsp; &nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>Any modifications to this Privacy Policy</strong>&nbsp;</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> There is a possibility that we will update Our Privacy Policy at some point in the future. By publishing the updated Privacy Policy on this page, we will keep you apprised of any modifications that may occur.&nbsp; &nbsp; &nbsp;Prior to the change going into effect, we will notify you via email and/or a prominent notice on Our Service, and we will also update the &apos;Last updated&apos; date that is located at the top of this Privacy Policy.&nbsp; &nbsp; &nbsp;It is strongly recommended that you check this Privacy Policy on a regular basis for any modifications. At the time that they are posted on this page, any modifications to this Privacy Policy will become effective.&nbsp; &nbsp; </Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>Get in Touch With Us</strong></Text>
   
  <Text as="p" className="mt-3 w-full !font-normal leading-7">Please do not hesitate to get in touch with us if you have any inquiries regarding this Privacy Policy.</Text>
  <Text as="p" className="mt-3 w-full !font-normal leading-7"> You can reach us at contact@quickall.com.</Text>
  
  <Heading as="h1">Appendix A: California Consumer Privacy Act Addendum </Heading>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Additional provisions applicable to processing personal information of California residents.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><span  >Scope and Applicability</span></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >This Appendix A to the Privacy Policy (&ldquo;Appendix A&rdquo;) applies to California residents and outlines individuals&rsquo; rights and choices with respect to the processing of your personal information under the California Consumer Privacy Act (&ldquo;CCPA&rdquo;). This Appendix A controls to the extent it conflicts with any provision in the main body of the Privacy Policy.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><span  >Data Collection, Use and Sharing</span></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >The CCPA requires that we inform individuals whether we collect any categories of personal information as classified under the CCPA, and whether we share this information with selected recipients for specific purposes.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><em><span  >Categories of Information</span></em></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >We collect the following categories of information as classified under CCPA:</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;<strong>Personal identifiers -</strong> We collect name, IP address, email address, and username.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;<strong>Commercial information</strong> - From QuickallNeuro-net Customers, we collect payment information such as sender/beneficiary account names and numbers; descriptions of transactions; payment amounts and instructions; and sender/beneficiary addresses and phone numbers. We also collect information about the products requested and records of services purchased.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;<strong>Internet or other network activity information</strong> - We collect IP address, device information, clickstream data, and dates and times of visits to our Site.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;<strong>Geolocation</strong>- We collect geolocation information directly from Quickall.com customers and Site visitors and, on a less granular level, automatically on the Quickall platform through the use of cookies and related technologies.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Please see the &ldquo;How We Collect Your Information&rdquo; section in our Privacy Policy to see the full description of the information that we collect.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Publicly Available Information: Personal information does not include publicly available information. For purposes of this paragraph, &ldquo;publicly available&rdquo; means information that is lawfully made available from federal, state, or local government records.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><em><span  >Sharing Information</span></em></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >We share the information collected with third-party service providers such as suppliers, vendors, business partners (e.g., advertising partners, consultants) to operate our business and provide the&nbsp;</span><strong>Quickall.com services. We do not sell personal information.</strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><em>Using Information</em></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">We collect and use personal information to:</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">
<ul  >
    <li  >&bull;&nbsp; &nbsp; &nbsp;Interact with<strong>&nbsp;Quickall.com&nbsp;</strong>Customers;</li>
    <li  >&bull;&nbsp; &nbsp; &nbsp;Facilitate transactions;</li>
    <li  >&bull;&nbsp; &nbsp; &nbsp;Provide the Quickall.com Service;</li>
    <li  ><span  >&bull;&nbsp; &nbsp; &nbsp;Conduct internal research and development</span>;</li>
    <li  >&bull;&nbsp; &nbsp; &nbsp;Improve the quality and safety of our products and services; AND</li>
    <li  >&bull;&nbsp; &nbsp; &nbsp;Detect security incidents and prevent fraud.</li>
</ul>
</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Please see the &ldquo;How We Use Your Information&rdquo; section in the Privacy Policy for a full description of how we use personal information.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><span  >Your CCPA Rights</span></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >The CCPA grants California consumers certain rights in connection with the personal information we collect, as described below.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;<strong>Right to Know:</strong> You have the right to know the categories and specific pieces of your personal information we have collected in the previous 12 months.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;<strong>Right to Deletion:</strong> You have the right to request that we delete any of your personal information we have collected.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;<strong>Right to Request Information:</strong> You have the right to request information about the collection, sale, and disclosure of your personal information from the previous 12 months.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;<strong>Right to Opt-out of the Sale of Information:</strong> You have the right to opt-out of the sale of personal information we have collected about you.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;<strong>Right to Non-Discrimination:</strong> You have the right to not receive discriminatory treatment for exercising any of your CCPA rights. We do not treat individuals differently for exercising any of the rights described above.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><span  >Exercising Your Rights</span></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >If you wish to exercise any of the above rights, see the &ldquo;Contact Us&rdquo; section in this Appendix A. You may also authorize an individual to submit a verifiable consumer request relating to your personal information.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >We verify your request using your email address. Government identification may be required. We cannot respond to your request if we cannot verify your identity and/or authority to make the request on behalf of another and confirm the personal information related to you.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >If you wish to use an authorized agent to submit a request to opt-out on your behalf, you must provide the authorized agent written permission signed by you, the consumer. We may deny a request from an authorized agent if the agent cannot provide Quickall with your signed permission demonstrating that the agent is authorized to act on your behalf.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >We fulfill requests within 45 days of receiving your request. Please note that your request may be limited in certain cases, for example if complying with your request would conflict with:</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">
<ul  >
    <li  >&bull;&nbsp; &nbsp; &nbsp;Federal, state, or local law;</li>
    <li  >&bull;&nbsp; &nbsp; &nbsp;Regulatory inquiries;</li>
    <li  >&bull;&nbsp; &nbsp; &nbsp;Subpoenas; or</li>
    <li  >&bull;&nbsp; &nbsp; &nbsp;Exercising or defending legal claims.</li>
</ul>
</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><span  >Contact Us</span></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >To submit questions about Appendix A or to update or request changes to your personal information, please contact us at&nbsp;</span><strong>contact@quickall.com</strong> or write to us:</Text>
  
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Quickall finance Inc. &nbsp;Attention: Planetary Marshal&nbsp; &nbsp;1113 La Boice Drive, Glendale / Los Angeles, CA 91205 &nbsp;U.S.A.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&nbsp;</span></Text>
 
 
 <Heading as="h1"> Appendix B: General Data Protection Regulation Addendum</Heading>

 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&nbsp;</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Additional provisions applicable to the processing personal information of individuals based in the European Union (&ldquo;EU&rdquo;), the United Kingdom (&ldquo;UK&rdquo;), or Switzerland.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><span  >Scope and Applicability</span></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >This Appendix B to the Privacy Policy (&ldquo;Appendix B&rdquo;) applies to individuals based in the EU, UK, and Switzerland, and outlines their rights and choices regarding the processing of their personal information under the General Data Protection Regulation or related regulations (collectively, the &ldquo;GDPR&rdquo;). Capitalized terms not defined in this Appendix B are defined in the Privacy Policy above.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><span  >Data Collection, Use, and Sharing &nbsp; &nbsp; &nbsp;</span></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Quickall acts as a data controller for personal information that we collect about individuals while using the&nbsp;</span><strong>Quickall.com</strong> <span  >Service as described in the Privacy Policy.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Quickall acts as a data processor on behalf of our&nbsp;</span><strong>Quickall.com</strong> <span  >Customers for most of the personal information we process through the&nbsp;</span><strong>Quickall.com</strong> <span  >Services. Our&nbsp;</span><strong>Quickall.com</strong> <span  >Customers primarily control what personal information we collect and process through the Service and how we use it, and we only process information in accordance with their instructions.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Quickall acts as a data controller for certain limited personal information collected from individuals working with&nbsp;</span><strong>Quickall.com</strong> <span  >Customers as required to provide the&nbsp;</span><strong>Quickall.com</strong> <span  >Service as described in the Privacy Policy.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >This Appendix B does not apply to any personal information that we process as a processor, as we only process that information on behalf of the&nbsp;</span><strong>Quickall.com</strong> Customer and in accordance with our agreement with them. A <strong>Quickall.com</strong> Customer that has entered into an agreement to use the<strong>&nbsp;Quickall.com</strong> Services controls its instance of the Service and any associated data.</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">We collect and process personal information about you only where we have a legal basis for doing so under applicable data protection laws. Our legal bases include processing personal information under:</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Your consent:</strong> Where appropriate or legally required, we collect and use information about you subject to your consent. For example, we collect your email address when you sign up for emails and updates on the&nbsp;<strong>Quickall.com</strong> Services.</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>Performance of contract:</strong> We collect and use information about you to contract with you or to perform a contract that you have with us. For example, we collect your name, email, and billing addresses when you purchase the&nbsp;<strong>Quickall.com</strong> Services and process transactions.</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Legitimate interests: We collect and use information about you for our legitimate interests to improve the&nbsp;<strong>Quickall.com</strong> Services, deliver content, optimize your experience, and market the&nbsp;<strong>Quickall.com</strong> Services. For example, we collect your IP address and device information when you use the&nbsp;<strong>Quickall.com</strong> Services to prevent malicious activity and fraudulent transactions.</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Compliance with laws: We may also collect and use information about you:</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;As required by law, such as to comply with a subpoena or similar legal process;</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;When we believe in good faith that disclosure is necessary to protect our rights or property, protect your health and safety or the health and safety of others, investigate fraud, or respond to a government request; or</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">&bull;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;If we participate in a merger, acquisition, or sale of all or a portion of its assets.</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><span  >Transfer of Personal Information to Other Countries</span></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >We transfer your personal information to countries outside the EU, UK, or Switzerland, including, but not limited to the United States, where Quickall finance Inc. Corporate Headquarters is located, and where our IT systems (including email) are located. When required for the provision of the&nbsp;</span><strong>Quickall.com</strong> <span  >Service, we may transfer and store personal information to locations in or outside the EU, UK, or Switzerland.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >If we transfer your personal information out of the EU, UK, or Switzerland and are required to apply additional safeguards to your personal information under European data protection legislation, we will do so. Such safeguards may include applying the European Commission-approved standard contractual data protection clauses or other appropriate legal mechanisms.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><span  >Our Commitment to the EU-U.S. Data Privacy Framework (EU-U.S. DPF) and the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. Data Privacy Framework (Swiss-U.S. DPF) and to the rights of EU and UK individuals and Swiss individuals.</span></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Quickall complies with the EU-U.S. Data Privacy Framework (EU-U.S. DPF) and the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. Data Privacy Framework (Swiss-U.S. DPF) as set forth by the U.S. Department of Commerce. Quickall has certified to the U.S. Department of Commerce that it adheres to the EU-U.S. Data Privacy Framework Principles (EU-U.S. DPF Principles) with regard to the processing of personal data received from the European Union and the United Kingdom in reliance on the EU-U.S. DPF and the UK Extension to the EU-U.S. DPF. Quickall has certified to the U.S. Department of Commerce that it adheres to the Swiss-U.S. Data Privacy Framework Principles (Swiss-U.S. DPF Principles) with regard to the processing of personal data received from Switzerland in reliance on the Swiss-U.S. DPF. If there is any conflict between the terms in this privacy policy and the EU-U.S. DPF Principles and/or the Swiss-U.S. DPF Principles, the Principles shall govern. To learn more about the Data Privacy Framework (DPF) program, and to view our certification, please visit https://www.dataprivacyframework.gov/</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Quickall is subject to the investigatory and enforcement powers of the Federal Trade Commission (FTC). Under certain circumstances you may request arbitration by the Data Privacy Framework Arbitration Panel for resolution of any claimed violations of the DPF Principles not resolved by any of the other mechanisms under the DPF Principles. For more information, please visit https://www.dataprivacyframework.gov/s/article/A-Scope-dpf?tabset-35584=2.In cases of onward transfer to third parties of personal information received pursuant to the DPF Principles, Quickall shall remain potentially liable.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><span  >Data Subject Rights</span></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >You have certain rights related to your personal information when you use the&nbsp;</span><strong>Quickall.com</strong> Services. Some of these rights only apply in certain circumstances, as set out below. We also describe how to exercise these rights in the &ldquo;Exercising your Rights&rdquo; section of this Appendix B.</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">If you are an end user of the<strong>&nbsp;Quickall.com</strong> Services seeking to access, correct, amend, or delete personal information, please contact the<strong>&nbsp;Quickall.com</strong> Customer (the data controller) that has transferred such data to us for processing. If the<strong>&nbsp;Quickall.com</strong> Customer receives a data subject request and sends the request to us, we will respond to the <strong>Quickall.com</strong> Customer&rsquo;s request within the agreed timeframe outlined in our Customer agreement. The <strong>Quickall.com</strong> Customer is responsible for responding to its end user data subject requests as determined under the applicable local data protection law.</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >The GDPR provides data subjects with the following rights:</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;Right of Access: Data subjects have the right to request access and receive certain information about how we use their personal information and who we share it with.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;Right to Rectification: Data subjects have the right to request correction of personal information we hold about them where it is inaccurate or incomplete.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;Right to Data Portability: Data subjects have the right to request a copy of data we hold about them in a structured, machine-readable format, and to ask us to share this information with another entity.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;Right to Erasure: Data subjects have the right to request deletion of the personal information we hold about them: &nbsp;</span></Text>

 <Text as="p" className="pl-[100px] mt-3 w-full !font-normal leading-7">
<ul  >
    <ul  >
        <li  >&bull;&nbsp; &nbsp; &nbsp;Where one believes that it is no longer necessary for us to hold their personal information;</li>
        <li  >&bull;&nbsp; &nbsp; &nbsp;Where we are processing personal information based on legitimate interests and a data subject objects to such processing, and we cannot demonstrate an overriding legitimate ground for the processing;</li>
        <li  >&bull;&nbsp; &nbsp; &nbsp;Where a data subject has provided their personal information to us with consent, and they wish to withdraw their consent and there is no other ground under which we can process their personal information; or</li>
        <li  >&bull;&nbsp; &nbsp; &nbsp;Where a data subject believes their personal information is being unlawfully processed by us.</li>
    </ul>
</ul>

</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;Right to Restriction of Processing: Data subjects have the right to ask us to restrict (stop any active) processing of their personal information:</span></Text>

 <Text as="p" className="pl-[100px] mt-3 w-full !font-normal leading-7">
<ul  >
    <ul  >
        <li  >&bull;&nbsp; &nbsp; &nbsp;Where they believe their personal information is inaccurate and while we verify accuracy;</li>
        <li  >&bull;&nbsp; &nbsp; &nbsp;Where we want to erase their personal information as the processing is unlawful, but they want us to continue to store it;</li>
        <li  >&bull;&nbsp; &nbsp; &nbsp;Where we no longer need their personal information for our processing, but they require us to retain the data for the establishment, exercise, or defense of legal claims; or</li>
        <li  >&bull;&nbsp; &nbsp; &nbsp;Where they have objected to us processing their personal information based on our legitimate interests and we are considering their objection.</li>
    </ul>
</ul>
</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;Right to Object: Data subjects can object to our processing of their personal information based on our legitimate interests. We will no longer process their personal information unless we can demonstrate an overriding legitimate purpose.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;Objection to Marketing, Automated Decision Making, and Profiling: Data subjects have the right to object to our processing of personal information for marketing communications, automated decision making and profiling. We will stop processing the data for that purpose.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >&bull;&nbsp; &nbsp; &nbsp;Withdrawal of Consent: Where a data subject has provided consent for us to process their personal information, they can withdraw their consent by emailing privacy@quickall.com &nbsp;</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Please note that before we respond to requests for information, we will require that identity verification, or the identity of any data subject for whom one is requesting information. Our verification methods may include requesting that the data subject log into their Quickall account, confirm their contact information or email address, and/or provide documents for identity verification.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7">Quickall commits to resolve complaints about our collection or use of your personal information. EU, UK, and Swiss individuals with inquiries or complaints regarding our privacy policy should first contact Quickall at&nbsp;<a href="mailto:contact@quickall.com">privacy@quickall.com</a> or at our mailing address at:</Text>
  
 <Text as="p" className="mt-3 w-full !font-normal leading-7">
  <strong>
<ul  >
    <li  >Quickall finance Inc.</li>
    <li  >Attention: Planetary Marshal</li>
    <li  >113 La Boice Drive</li>
    <li  >Glendale / Los Angeles, CA 91205</li>
</ul>
</strong>
</Text>
  
  
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >Quickall has further committed to refer unresolved complaints to JAMS, an alternative dispute resolution provider located in the United States. If you do not receive timely acknowledgment of your complaint from us, or if we have not addressed your complaint to your satisfaction, please visit https://www.jamsadr.com/eu-us-privacy-shield for more information or to file a complaint. The services of JAMS are provided at no cost to you.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><span  >Exercising Your Rights</span></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >To exercise any of the rights above, please contact us as noted in the &ldquo;Contact Us&rdquo; section in this Appendix B. If you are a Customer end user, contact the applicable Customer acting as the data controller directly to fulfill any requests.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >We will fulfill your request within 30 days of receipt. Please note that the above rights may be limited in the following situations:</span></Text>

 <Text as="p" className="mt-3 w-full !font-normal leading-7">
<ul  >
    <li  >&bull;&nbsp; &nbsp; &nbsp;Where fulfilling your request would adversely affect other individuals, company trade secrets or intellectual property;</li>
    <li  >&bull;&nbsp; &nbsp; &nbsp;Where there are overriding public interest reasons; or</li>
    <li  >&bull;&nbsp; &nbsp; &nbsp;Where we are required by law to retain your personal information.</li>
</ul>
</Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >If you have unresolved concerns, we encourage you to come to us in the first instance, but you are entitled to address any grievance directly to the relevant supervisory authority. If you are a Customer end user, we encourage you to reach out to the relevant Customer first to address any complaints.</span></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong><span  >Contacting Us</span></strong></Text>
 <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >To submit questions about Appendix B or to update or request changes to personal information, please contact us as outlined in the Privacy Policy or contact our European representative as outlined below.</span></Text>
  

 </div>
 </div>


        <Footer className="self-stretch" />
      </div>
     
   
    
    
    </>
  );
}
