import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, Button } from "../../components";
import Header from "../../components/Header";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const countries = [
  { code: '+1', name: 'United States' },
  { code: '+91', name: 'India' },
  { code: '+44', name: 'United Kingdom' },
  // Add more country codes as needed
];

export default function CreateaccountPage() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [countryCode, setCountryCode] = useState(countries[1].code);
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsError, setTermsError] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(validateEmail(value) ? '' : 'Please enter a valid email address');
  };

  const validatePhone = (phone) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(phone);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
    setPhoneError(validatePhone(value) ? '' : 'Please enter a valid 10-digit phone number');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordError(e.target.value === password ? '' : 'Passwords do not match');
  };

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
    setTermsError(e.target.checked ? '' : 'You must agree to the terms and conditions');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation before submitting
    if (!emailError && !phoneError && !passwordError && termsChecked) {
      setLoading(true);
      setApiError('');
      try {
        const response = await axios.post('http://134.122.118.11:3001/register', {
          email,
          phone: `${countryCode}${phone}`,
          password,
        });

        if (response.status === 200) {
          alert('Account created successfully!');
          navigate('/login');  // Redirect to login page after successful account creation
        }
      } catch (error) {
        setApiError('Failed to create account. Please try again later.');
      } finally {
        setLoading(false);
      }
    } else {
      if (!termsChecked) setTermsError('You must agree to the terms and conditions');
      alert('Please fix the errors in the form.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Account</title>
        <meta
          name="description"
          content="Join us by creating an account quickly. Sign up using your email or phone number and start enjoying our services. Secure and straightforward registration process."
        />
      </Helmet>
      <Header className="self-stretch" />

      {/* main content section */}
      <div className="bg-white-A700 pb-[45px]">
        <div>
          <div className="flex items-start mt-[80px] justify-between gap-5 md:flex-col  pl-[110px] md:p-5 md:px-5">
            <div className="mt-1 w-[39%] md:w-full">
              <div className="flex flex-col items-start gap-[30px]">
                <Text
                  size="lg"
                  as="p"
                  className="!text-black-900 bold"
                  style={{ fontSize: "30px" }}
                >
                  <b> Create your account</b>
                </Text>
                <Text
                  size="xs"
                  as="p"
                  className="mobile w-full leading-7 !text-black-900_99"
                >
                  To get started, we need you to create an account with us.
                  It&#39;s a quick and straightforward process that will only
                  take a few minutes.
                </Text>

                {/* input fields section */}
                <form className="CreateaccountPage" onSubmit={handleSubmit}>
                  <div className="flex items-start gap-6 self-stretch sm:flex-col forms-s ">
                    <div className="flex flex-1 flex-col gap-[21px] sm:self-stretch">
                      <div className="flex flex-col gap-[19px]">
                        <div className="flex flex-col items-start gap-[11px]">
                          <Text as="p">
                            <span className="text-black-900 ">
                              <b>Email Address</b>&nbsp;
                            </span>
                            <span className="text-deep_orange_A400">*</span>
                          </Text>
                          <input
                            className="h-12 custom-border"
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Enter email address"
                            required
                          />
                          {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                        </div>
                        <div className="flex flex-col items-start">
                          <Text as="p">
                            <span className="text-black-900">
                              <b>Phone No</b>.&nbsp;
                            </span>
                            <span className="text-deep_orange_A400">*</span>
                          </Text>
                          <select value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
                            {countries.map((country) => (
                              <option key={country.code} value={country.code}>
                                {country.name} ({country.code})
                              </option>
                            ))}
                          </select>

                          <input
                            className="h-12 custom-border"
                            type="tel"
                            value={phone}
                            onChange={handlePhoneChange}
                            placeholder="Enter phone number"
                            required
                          />
                          {phoneError && <p style={{ color: 'red' }}>{phoneError}</p>}
                        </div>
                      </div>

                      <div className="flex flex-col items-start gap-2.5">
                        <Text as="p">
                          <span className="text-black-900">
                            <b>Password</b>&nbsp;
                          </span>
                          <span className="text-deep_orange_A400">*</span>
                        </Text>
                        <input
                          className="h-12 custom-border"
                          type="password"
                          value={password}
                          onChange={handlePasswordChange}
                          placeholder="Set password"
                          required
                        />
                      </div>

                      <div className="flex flex-col items-start gap-[11px]">
                        <Text as="p">
                          <span className="text-black-900">
                            <b>Confirm Password </b>&nbsp;
                          </span>
                          <span className="text-deep_orange_A400">*</span>
                        </Text>
                        <input
                          className="h-12 custom-border"
                          type="password"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          placeholder="Confirm password"
                          required
                        />
                        {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
                      </div>

                      {/* terms and conditions section */}
                      <div className="flex flex-col gap-[35px] mt-[40px]">
                        <input type="checkbox" checked={termsChecked} onChange={handleTermsChange} required />
                        <div className="mt-[-70px] ml-[40px]">
                          Your personal data will be used to support your experience throughout 
                          this website, to manage access to your account, and for other 
                          purposes described in our &nbsp;
                          <a style={{ display: 'inline-block' }} href="/privacypolicy" target="_blank" rel="noopener noreferrer" className="text-blue-500">Privacy Policy</a>
                          &nbsp;and&nbsp;
                          <a href="/termsofservices" style={{ display: 'inline-block' }} target="_blank" rel="noopener noreferrer" className="text-blue-500">Terms & Condition</a>
                        </div>
                        <Button shape="round" className="w-full" type="submit" style={{ color: "#ffffff"}}>
                          {loading ? 'Creating...' : 'Create an Account'}
                        </Button>
                      </div>
                      {apiError && <p style={{ color: 'red' }}>{apiError}</p>}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="w-[34%] sm:hidden relative h-[792px]">
              <div className="absolute h-[792px] inset-[0] m-auto">
                {/* background image */}
                <img
                  src="images/img_createaccountpage.png"
                  className="h-[792px] m-auto object-cover w-full"
                  alt="create account page background"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
